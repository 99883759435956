var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "setting-general setting-general-edit" }, [
    _c("h1", [_vm._v("設定 - 一般 - 編集")]),
    _vm._v(" "),
    _c("div", { staticClass: "card-wrapper gray-border" }, [
      _c("h3", { staticClass: "card-title" }, [
        _vm._v("\n            設定値一覧\n        "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "item" }, [
          _c("label", [_vm._v("項目名")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.setting.key)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("label", [_vm._v("値")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.setting.value,
                  expression: "setting.value",
                },
              ],
              attrs: { type: "number" },
              domProps: { value: _vm.setting.value },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.setting, "value", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("label", [_vm._v("備考")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.setting.memo)),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "button_area" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-secondary return",
          attrs: { href: "/setting/general" },
        },
        [_vm._v("戻る")]
      ),
      _vm._v(" "),
      _vm.loading == false
        ? _c(
            "a",
            {
              staticClass: "btn btn-primary",
              on: {
                click: function ($event) {
                  return _vm.updateSetting()
                },
              },
            },
            [_vm._v("保存")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }