<template>
    <div class="alert alert-danger maintenance-notice" :class="extra_div_classes" role="alert">
        <svg xmlns="http://www.w3.org/2000/svg"
             width="1rem" height="1rem"
             fill="currentColor"
             class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
             viewBox="0 0 16 16"
             role="img"
             aria-label="Warning:">
            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
        </svg>
        <span v-html="message_html"></span>
    </div>
</template>
<script>
 export default {
     components: {
     },
     props: {
         extra_div_classes:{type:String,default:''},
         message_html:{type:String,require:true},
     },
     data: function() {
         return {
         }
     },
     computed: {
     },
     methods: {
     }
 }
</script>
