<template>
    <div class="setting-genre setting-genre-create">
        <h1>設定 - ジャンル - 登録</h1> 
        <div v-if="errors.length > 0" class="alert alert-danger">
            <ul class="error_ul"><li v-for="error in errors"> {{ error }} </li></ul>
        </div>
        <div class="card-wrapper gray-border">
            <h3 class="card-title">
                設定値一覧
            </h3>
            <div class="card-body">
                <div class="item">
                    <label>表示順</label>
                    <div class="value">
                        <input type="number" v-model="genre.no" >
                    </div>
                </div>
                <div class="item">
                    <label>ジャンル名</label>
                    <div class="value">
                        <input type="text" v-model="genre.name" >
                    </div>
                </div>
            </div>
        </div>
        <div class="button_area">
            <a class="btn btn-secondary return" href="/setting/genre">戻る</a>
            <a class="btn btn-primary" v-on:click="createGenre()">登録</a>
        </div>
    </div>
</template>
<script>
    export default {
        data: function() { 
            return {
                errors: [],
                genre: {
                    no: "",
                    name: ""
                }
            }
        },
        methods: {
            createGenre: function() {
                var self = this;
                var data = {
                    no: self.genre.no,
                    name: self.genre.name
                };
                axios.post("/api/v1/create/genre",
                    data
                ).then(response => {
                    if(response.data.status == "SUCCESS") {
                        window.location.href = "/setting/genre";
                    } else {
                        self.errors = response.data.message;
                        window.scrollTo(0, 0);
                    }
                }).catch(error => { 
                    self.$toasted.show('登録に失敗しました。');
                    console.log(error);
                })

            },
        }
    }
</script>
