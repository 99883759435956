export default {
    methods: {
        saveLocalStrage: function(key, value, expiredMinutes) {
            if (!key || !value) return;
            const expired = (expiredMinutes || 30) * 60 * 1000; // デフォルト期限30分, ミリ秒に変換

            localStorage.setItem(key, JSON.stringify({
                value: value,
                expired: (new Date()).getTime() + expired,
            }));
        },
        loadLocalStrage: function(key) {
            if (!key) return null;

            const json = JSON.parse(localStorage.getItem(key));
            if (!json) return null;
            if (!json.expired || (new Date()).getTime() < json.expired) return json.value;

            // 期限切れの場合は消去
            localStorage.removeItem(key);
            return null;
        },
        removeLocalStrage: function(key) {
            if (!key) return;
            localStorage.removeItem(key);
        },
    }

};
