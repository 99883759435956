var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "h3",
    { staticClass: "card-title", attrs: { for: _vm.getForKey() } },
    [
      _vm._v("\n  " + _vm._s(_vm.title_ja()) + "\n  "),
      _vm.required
        ? _c("span", { staticClass: "required" }, [
            _vm._v(" (" + _vm._s(_vm.required_ja()) + ")"),
          ])
        : _vm._e(),
      _vm._v("\n  / " + _vm._s(_vm.title_en()) + "\n  "),
      _vm.required
        ? _c("span", { staticClass: "required" }, [
            _vm._v(" (" + _vm._s(_vm.required_en()) + ")"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.descr != "" ? _c("span", [_vm._v(_vm._s(_vm.descr))]) : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }