var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-body" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.flg_mail,
          expression: "flg_mail",
        },
      ],
      staticClass: "form-control mail-check",
      attrs: { type: "checkbox", id: _vm.id },
      domProps: {
        checked: Array.isArray(_vm.flg_mail)
          ? _vm._i(_vm.flg_mail, null) > -1
          : _vm.flg_mail,
      },
      on: {
        change: function ($event) {
          var $$a = _vm.flg_mail,
            $$el = $event.target,
            $$c = $$el.checked ? true : false
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v)
            if ($$el.checked) {
              $$i < 0 && (_vm.flg_mail = $$a.concat([$$v]))
            } else {
              $$i > -1 &&
                (_vm.flg_mail = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
            }
          } else {
            _vm.flg_mail = $$c
          }
        },
      },
    }),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.formatted_mail_send_date,
          expression: "formatted_mail_send_date",
        },
      ],
      staticClass: "form-control mail-date",
      attrs: { type: "date", min: "2000-01-01", max: "2037-12-31" },
      domProps: { value: _vm.formatted_mail_send_date },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.formatted_mail_send_date = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.formatted_mail_send_hour,
            expression: "formatted_mail_send_hour",
          },
        ],
        staticClass: "form-control mail-time",
        on: {
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.formatted_mail_send_hour = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
        },
      },
      _vm._l(_vm.optionTimes, function (time) {
        return _c("option", { key: time.id }, [_vm._v(_vm._s(time))])
      }),
      0
    ),
    _vm._v(" "),
    _c("span", { staticClass: "unit" }, [_vm._v("：")]),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.formatted_mail_send_minute,
            expression: "formatted_mail_send_minute",
          },
        ],
        staticClass: "form-control time",
        on: {
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.formatted_mail_send_minute = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
        },
      },
      _vm._l(_vm.optionMinutes, function (time) {
        return _c("option", { key: time.id }, [_vm._v(_vm._s(time))])
      }),
      0
    ),
    _vm._v(" "),
    _vm.last_noticed_datetime != null && _vm.last_noticed_datetime != ""
      ? _c("p", { staticClass: "last-noticed-datetime" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm._ct("title.last_noticed_datetime")) +
              " : " +
              _vm._s(_vm.last_noticed_datetime) +
              "\n    "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }