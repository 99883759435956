<template>
    <div id="browse-post">
        <h1>掲示情報 / Information</h1>
        <div v-if="!isGuestUser()" class="unread">
            <a v-bind:href="makeUnreadPath(postData.id)">
                <fa-icon icon="envelope"></fa-icon>
                未読に戻す/Put back on unread
            </a>
        </div>
        <div id="post" class="card-wrapper green-border">
            <h2 v-if="isEmptyPostData()"  class="title">
                <div class="title-left">
                    Loading…
                </div>
            </h2>
            <h2 v-else class="title">
                <div class="title-left">
                    {{ postData.title }}
                </div>
                <div v-if="!isGuestUser()" class="title-right">
                    <div class="fav"
                         v-on:click="toggleFav(markedFav, $event)"
                         v-bind:class="{'non-fav': !markedFav}"
                    >
                        <fa-icon icon="star"></fa-icon>
                    </div>
                </div>
            </h2>
            <div class="card-body">
                <div class="body dummy-height" v-if="isEmptyPostData() || postData.body == '' "></div>
                <div class="body" v-html="formatMessageBody(postData.body)" v-else></div>
                <div class="information">
                    <div class="info">
                        <div class="english-notation">Contributor</div>
                        <div class="post-item">
                            <label>掲載者</label>
                            <div class="value" v-if="!isEmptyPostData()">
                                {{ postData.contributor_name }}
                            </div>
                        </div>
                    </div>
                    <div class="info">
                        <div class="english-notation">Contact information</div>
                        <div class="post-item">
                            <label>連絡先</label>
                            <div class="value" v-if="!isEmptyPostData()">
                                {{ postData.contact_mail }}
                            </div>
                        </div>
                    </div>
                    <div class="info">
                        <div class="english-notation">Posting period</div>
                        <div class="post-item">
                            <label>掲載期間</label>
                            <div class="value" v-if='!isEmptyPostData() && postData.publish_from !== null && postData.publish_from !== ""'>
                                {{ disp_datetime_format(postData.publish_from) }}
                                &nbsp;～&nbsp;
                                {{ disp_datetime_format(postData.publish_to) }}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="info">
                         <label>メール最終送信</label>
                         <div class="value"
                         v-if='this.postData.mail_send_date == null ||
                         this.postData.mail_send_date == ""'>メール配信設定なし
                         </div>
                         <div class="value"
                         v-else-if='this.postData.mail_send_date.date !== ""'>
                         {{ toStringDate( this.postData.mail_send_date.date ) }}
                         {{ "  " + this.postData.mail_send_time }}:00
                         </div>
                         </div> -->
                    <div class="info">
                        <div class="english-notation">Target student</div>
                        <div class="post-item">
                            <label>対象学生所属</label>
                            <template v-if="!isEmptyPostData()">
                                <div class="value" v-if="postData.selection.selectStudents == 'allStudents'">
                                    {{ makeBelongSimpleTargetName() }}
                                </div>
                                <div v-else class="value">
                                    <div v-for="belong in postData.selection.target_belongs_original">
                                        {{ makeBelongName(belong) }}
                                        <span v-for="grade in belong.grades">{{ " " + grade }}年</span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="info attachments">
                        <div class="english-notation">Attachement
                        </div>
                        <div class="post-item">
                            <label>
                                添付ファイル
                            </label>
                            <div class="value" v-if="postData.attachments == null || postData.attachments.length <= 0 ">添付なし</div>
                            <div class="value" v-else>
                                <div v-for="attachment in postData.attachments">
                                    <a v-bind:href="makeDownloadPath(postData.id,attachment.id)">
                                        <fa-icon icon="paperclip"></fa-icon>
                                        {{ attachment.name }}　({{ byte_to_kilobyte(attachment.size) + 'KB'}})
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn_area_right">
                        <a class="btn btn-primary"
                           v-if="postData.isEditable"
                           v-bind:href="makeEditPath(postData.id)">編集 / Edit</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn_area detail-close">
            <a class="btn btn-close" onclick="window.close();"> 閉じる / Close</a>
        </div>
    </div>
</template>

<script>
 export default {
     props: {
         auth_json_str:{type:String,required:true},
     },
     data: function(){
         return {
             auth: null,
             simple_target_name_map: {
                 // detailでは正規非正規は見せない
                 undergraduate_regular: '学部生',
                 undergraduate_other: '学部生',
                 graduate_regular: '大学院生',
                 graduate_other: '大学院生',
             },
             markedFav: false,
             isFavProcessiong: false,
             postData: {},
         }
     },
     mounted: function(){
         this.auth = JSON.parse(this.auth_json_str);
         this.getPost();
     },
     methods: {
         makeBelongSimpleTargetName: function(){
             const sel = this.postData.selection.simpleTargetSelections;
             const checked = sel.filter(x => x.value);
             const checked_labels = checked.map(x => this.simple_target_name_map[x.id]);
             const uniq_labels = [...(new Set(checked_labels))].sort().reverse();
             return uniq_labels.join('・');
         },
         getPost: async function() {
             const document_id = window.location.href.split('/').pop();
             const data = await this.get_post(document_id);
             this.postData = data;
             this.markedFav = this.postData.isFavorite;
         },
         isEmptyPostData: function() {
             return Object.keys(this.postData).length == 0;
         },
         makeBelongName: function(belong_info) {
             const target_keys = ['lv1','lv2','lv4','lv5'];
             let names = [];
             target_keys.forEach(function(key){
                 names.push(belong_info[key].name);
             });
             return names.join(' ');
         },
         formatMessageBody: function(str){
             str = this.activateLink(str,{target:'_blank'});
             str = str.replace(/\r?\n/g, '<br>');
             return str;
         },
         toggleFav: async function(marked, $event){
             if ( this.isFavProcessiong ) {
                 $event.stopPropagation()
                 return;
             }
             this.isFavProcessiong = true;
             if( marked ) {
                 await this.removeFav(this.postData.id);
             } else {
                 await this.markFav(this.postData.id);
             }
             this.isFavProcessiong = false;
             $event.stopPropagation()
         },
         markFav: async function(post_id) {
             const result = await this.mark_favorite(post_id);
             if (result) {
                 this.markedFav = true;
                 this.$toasted.info(this._ct('message.info.mark_favorite'));
                 return;
             }
         },
         removeFav: async function(post_id) {
             const result = await this.remove_favorite(post_id);
             if (result) {
                 this.markedFav = false;
                 this.$toasted.info(this._ct('message.info.remove_favorite'));
                 return;
             }
         },
         isGuestUser: function() {
             if (this.auth == null) return true;
             return this.auth.is_guest;
         },
     }
 }
</script>
