export default {
    methods: {
        htmlspecialchars: function(text) {
            let entities = [['amp', '&'], ['apos', '\''], ['lt', '<'], ['gt', '>']];
            for (let i=0,max=entities.length;i<max;i++){
                text = text
                    .replace(new RegExp(entities[i][1],'g'),"&"+entities[i][0]+';')
                    .replace('"','&quot;');
            }
            return text;
        },
        activateLink: function(str,attr={}) {
            let pattern = /(((http|https):\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/gi; // ']))/;
            let attrs = Object.keys(attr)
                .map(key => key + '="' + attr[key] + '"');
            str = this.htmlspecialchars(str);
            str = str.replace(pattern,function(match){
                return '<a href="' + match + '" ' + attrs.join(' ') + '>' + match + '</a>';
            });
            return str;
        },
        // 以下、主にリンクなどで埋め込む用のもの。
        // js内で処理するapiなどはapi.jsで持っています。
        makeDetailPath: function(post_id) {
            return `/general/detail/${post_id}`;
        },
        makeEditPath: function(post_id) {
            return `/general/edit/${post_id}`;
        },
        makeDownloadPath: function(post_id,attachment_id) {
            return `/api/v1/general/download/${post_id}/${attachment_id}`;
        },
        makeUnreadPath: function(post_id) {
            return `/api/v1/general/post/setUnread/${post_id}`;
        },
        makeTopPath: function() {
            return '/general';
        },
    }
};
