<template>
    <!-- 子階層にコンポーネントを持つことを想定した作りとしている。 -->
    <div>
        <h2 class="card-title">
            <span>記事一覧 / Article List</span>
            <!-- <span class="reload" v-on:click="reload()"><i class="fas fa-sync-alt"></i></span> -->
        </h2>
        <div id="result" class="card-wrapper">
            <div class="card-body">

                <!-- 子からsetFilterメソッドが実行される -->
                <pager-filter
                    :auth_json_str="auth_json_str"
                    @selectedUnit="selectedUnit"
                    @selectedFilter="selectedFilter"
                ></pager-filter>

                <!-- 子にページ情報を渡す -->
                <pager-label
                    :currentPage="currentPage"
                    :unit="unit"
                    :postsCount="postsCount"
                ></pager-label>

                <div class="loading-content" v-if="isLoading === true">
                    <div class="loading-message"><img src="/images/ajax-loader.gif" > Loading…</div>
                </div>

                <div class="container">
                    <div id="postList" class="row">
                        <div v-if="this.viewPosts.length === 0 && !isLoading "
                             class="no_post">投稿がありません</div>
                        <div v-else
                             class="col-12 card_block"
                             v-for="post in this.viewPosts"
                             v-bind:class="{'read-card': !post.isUnread}"
                        >
                            <a class="post"
                               target="blank"
                               v-bind:href="makeDetailPath(post.id)"
                               v-bind:class="{'col-12': !post.isUnread}"
                            >
                                <div class="title">
                                    <div class="title-left">
                                        <span v-if="post.is_draft == true"><i class="fas fa-edit"></i>(下書き/Draft) </span>
                                        {{ post.title }}
                                    </div>
                                    <div v-if="!isGuestUser()" class="title-right">
                                        <div v-if="isNewPost(post)" class="unread">NEW</div>
                                        <div class="fav"
                                             v-on:click="toggleFav(post.id, post.isFavorite, $event)"
                                             v-bind:class="{'non-fav': !post.isFavorite}"
                                        >
                                            <fa-icon icon="star"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div class="information">
                                <a class="post"
                                   target="blank"
                                   v-bind:href="makeDetailPath(post.id)"
                                   v-bind:class="{'col-12': !post.isUnread}"
                                >
                                    <div class="waku">
                                        <div class="post-item-area">
                                            <div class="english-notation">Contributor</div>
                                            <div class="post-item">
                                                <label>掲載者&emsp;</label>
                                                <div class="post-value">{{ post.contributor_name }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="waku">
                                        <div class="post-item-area">
                                            <div class="english-notation">Date of update</div>
                                            <div class="post-item">
                                                <label>更新日時</label>
                                                <div>{{ disp_date_format(post.updated_at) }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <div class="edit-btn-area">
                                    <a v-if="post.isEditable" class="btn btn-primary" target="blank" v-bind:href="makeEditPath(post.id)">編集 / Edit</a>
                                </div>
                            </div>
                            <div class="read"></div>
                        </div><!-- col -->
                    </div><!-- row -->
                </div><!-- container -->

                <!-- 子からsetCurrentPageメソッドが実行される -->
                <laravel-paginator
                    @setCurrentPage="setCurrentPage"
                    :currentPage="currentPage"
                    :unit="unit"
                    :postsCount="postsCount"
                ></laravel-paginator>
            </div>
        </div>
    </div>
</template>
<script>
 import moment from "moment";
 import PagerLabel   from '../common/PagerLabel.vue';
 import PagerFilter  from '../common/PagerFilter.vue';
 import PagerLinks   from '../common/PagerLinks.vue';

 export default {
     components: {
         PagerLabel,
         PagerFilter,
         PagerLinks
     },
     props: {
         searchWord:{type:String,default:''},
         showMyBelongs:{type:Boolean,default:true},
         auth_json_str:{type:String,required:true},
     },
     data: function() {
         return {
             auth: null,
             genre: "",
             isFavProcessiong: false,
             currentPage: 1, // pager情報
             unit: 20,        // pager情報
             postsCount: 0,  // pager情報
             viewPosts: [],  // 表示用配列
             filterdPosts: [],   // フィルター後の全件配列
             page_data: null, // laravel pagination
             posts: [],          // 全件配列
             searchTerm: {   // 検索条件
                 word: null,
                             datetime: null,
                             offset: 0,
                             count: 1000
             },
             filter: "NONE", // フィルタ
             user: {},        // ログイン情報
             isLoading: false,
         }
     },
     mounted: function() {
         this.auth = JSON.parse(this.auth_json_str);
     },
     methods: {
         reload: function() {
             this.getPosts(null);
         },
         resetPage: function() {
             this.currentPage = 1;
         },
         getPosts: async function(genre=null,doPageReset=true){
             if( genre === null || genre === undefined ) {
                 genre = this.genre;
             } else {
                 this.genre = genre;
             }
             this.posts = [];
             this.viewPosts = [];
             this.postsCount = 0;
             if (doPageReset) this.resetPage();
             const params = {
                 text: this.searchWord,
                 genre: this.genre,
                 filter: this.filter,
                 showmybelongs: this.showMyBelongs,
                 unit: this.unit,
                 page: this.currentPage,
             };

             this.isLoading = true;

             const res_data = await this.get_post_list(params);
             this.page_data    = res_data;
             this.posts        = res_data.data;
             this.filterdPosts = res_data.data;
             this.viewPosts    = res_data.data;
             this.currentPage  = this.page_data.current_page;
             this.postsCount   = this.page_data.total;
             this.unit         = this.page_data.per_page * 1;

             this.isLoading = false;
         },
         toggleFav: async function(post_id, marked, $event){
             $event.preventDefault();
             if ( this.isFavProcessiong ) {
                 $event.stopPropagation()
                 return;
             }

             this.isFavProcessiong = true;
             if( marked ) {
                 await this.removeFav(post_id);
             } else {
                 await this.markFav(post_id);
             }
             this.isFavProcessiong = false;
             $event.stopPropagation()

         },
         markFav: async function(post_id) {
             const result = await this.mark_favorite(post_id);
             if (result) {
                 this.$toasted.info(this._ct('message.info.mark_favorite'));
                 this.setFavoriteFlag(post_id,true);
             }
         },
         removeFav: async function(post_id) {
             const result = await this.remove_favorite(post_id);
             if (result) {
                 this.$toasted.info(this._ct('message.info.remove_favorite'));
                 this.setFavoriteFlag(post_id,false);
             }
         },
         setFavoriteFlag: function(post_id,is_favorite) {
             const target_post = this.posts.filter(function(p) {return p.id == post_id; })[0];
             target_post.isFavorite = is_favorite;
         },
         // 子のコンポーネントから呼び出される関数
         selectedUnit: function(val) {
             this.unit = val;
             this.resetPage();
             this.reload();
         },
         selectedFilter: function(val) {
             this.filter = val;
             this.resetPage();
             this.reload();
         },
         setCurrentPage: function(val) {
             this.currentPage = val;
             this.getPosts(null,false);
         },
         isNewPost: function(post) {
             // 現行仕様は未読に一律NEWが付く。
             return post.isUnread;

             /*
              * 現行仕様に若干違和感あり。
              * disp_update_atも見る方が自然な感じがするが...
              */
             // const edgeDayNumOfNewPost = 3;
             // const edgeDatetime = moment(post.disp_updated_at).add(edgeDayNumOfNewPost,'d');
             // const now = moment();
             // const isNewPost = edgeDatetime > now;
             // return post.isUnread && isNewPost;
         },
         isGuestUser: function() {
             if (this.auth == null) return true;
             return this.auth.is_guest;
         },
     }
 }
</script>
