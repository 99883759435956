var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.offset != null && _vm.getMax() > 0
    ? _c("div", [
        _c("div", { staticClass: "pager-label" }, [
          _vm._v(
            _vm._s(_vm.getFrom()) +
              "-" +
              _vm._s(_vm.getTo()) +
              " out of " +
              _vm._s(_vm.getMax())
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }