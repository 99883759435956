import moment from "moment";
export default {
    methods: {
        convert_observer: function(o) {
            return JSON.parse(JSON.stringify(o));
        },
        is_already_added_belong: function(list_elm, to_add_info) {
            const getStr = x => x == null ? '' : x;
            if (getStr(list_elm.lv1.code) != getStr(to_add_info.lv1.code)) return false;
            if (getStr(list_elm.lv2.code) != getStr(to_add_info.lv2.code)) return false;
            if (getStr(list_elm.lv4.code) != getStr(to_add_info.lv4.code)) return false;
            if (getStr(list_elm.lv5.code) != getStr(to_add_info.lv5.code)) return false;

            const grade_diff = to_add_info.grades.filter(g => !list_elm.grades.includes(g));
            if (grade_diff.length != 0) return false;

            const status_diff = to_add_info.status.filter(s => !list_elm.status.includes(s));
            if (status_diff.length != 0) return false;

            return true;
        },
        byte_to_kilobyte: function(bytenum) {
            let kb = bytenum >> 10;
            return kb == 0 ? 1 : kb;
        },
        disp_date_format: function(date) {
            const m = moment(date);
            return m.format('YYYY-MM-DD');
        },
        disp_datetime_format: function(date) {
            const m = moment(date);
            return m.format('YYYY-MM-DD HH:mm');
        },
        format_datetime: function(date,format='YYYY-MM-DD HH:mm') {
            const m = moment(date);
            return m.format(format);
        },
        moment_format: function(m) {
            if (m == null) return '';
            if (!m.isValid()) return '';
            return m.format();
        },
    }
};
