var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "setting-general setting-general-index" }, [
    _c("h1", [_vm._v("設定 - 一般")]),
    _vm._v(" "),
    _c("div", { staticClass: "card-wrapper gray-border" }, [
      _c("h3", { staticClass: "card-title" }, [
        _vm._v("\n            設定値一覧\n        "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "table",
          { staticClass: "table" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(this.settings, function (setting) {
              return _c("tr", [
                _c("td", [_vm._v(_vm._s(setting.key))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(setting.value))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(setting.memo))]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "a",
                    { attrs: { href: "/setting/general/edit/" + setting.id } },
                    [_vm._v("編集")]
                  ),
                ]),
              ])
            }),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("項目名")]),
      _vm._v(" "),
      _c("th", [_vm._v("値")]),
      _vm._v(" "),
      _c("th", [_vm._v("備考")]),
      _vm._v(" "),
      _c("th"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "button_area" }, [
      _c(
        "a",
        { staticClass: "btn btn-secondary", attrs: { href: "/setting" } },
        [_vm._v("戻る")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }