<template>
    <div class="setting-general setting-general-index">
        <h1>設定 - 一般</h1>
        
        <div class="card-wrapper gray-border">
            <h3 class="card-title">
                設定値一覧
            </h3>
            <div class="card-body">
                <table class="table">
                    <tr>
                        <th>項目名</th>
                        <th>値</th>
                        <th>備考</th>
                        <th></th>
                    </tr>
                    <tr v-for="setting in this.settings">
                        <td>{{ setting.key }}</td>
                        <td>{{ setting.value }}</td>
                        <td>{{ setting.memo }}</td>
                        <td>
                            <a v-bind:href="'/setting/general/edit/' + setting.id">編集</a>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="button_area">
            <a class="btn btn-secondary" href="/setting">戻る</a>
        </div>
    </div>
</template>
<script>
    export default {
        data: function() { 
            return {
                ignore_keys: [ // 編集させないキーを配列で監理
                    "in_maintenance"
                ],
                settings: [
                ]
            }
        },
        mounted: function() {
            this.getList();
        },
        methods: {
            getList: function() {
                var self = this;
                axios.get("/api/v1/setting/get/generals",
                    {}
                ).then(response => {
                    self.settings = response.data.data;
                    // in_maintenanceは編集させないので表示しない
                    for ( var idx in self.settings ) {
                        if( self.ignore_keys.indexOf(self.settings[idx].key) != -1 ) {
                            self.settings.splice(idx, 1); 
                        }
                    }
                }).catch(error => { 
                    console.log(error);
                })
            }
        }
    }
</script>
