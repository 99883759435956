<template>
    <div>
        <div class="pager-links" v-if="postsCount > 0">
          <div class="pager-link left"  data-type="first"   v-on:click="tapPager" :class="{ disabled: isPrevDisabled() }" :disabled="isPrevDisabled()"><fa-icon icon="angle-double-left"></fa-icon></div>
          <div class="pager-link "      data-type="prev"    v-on:click="tapPager" :class="{ disabled: isPrevDisabled() }" :disabled="isPrevDisabled()"><fa-icon icon="angle-left"></fa-icon></div>
          <div class="pager-link index" v-for="page in pageArray" v-on:click="tapPager" :class="{ current: isCurrent(page) }">{{ page }}</div>
          <div class="pager-link"       data-type="next"    v-on:click="tapPager" :class="{ disabled:  isNextDisabled() }" :disabled="isNextDisabled()"><fa-icon icon="angle-right"></fa-icon></div>
          <div class="pager-link right" data-type="last"    v-on:click="tapPager" :class="{ disabled:  isNextDisabled() }" :disabled="isNextDisabled()"><fa-icon icon="angle-double-right"></fa-icon></div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ["currentPage","unit","postsCount"],
        data: function() { 
            return {
                pageArray: [1],
                LINK_COUNT: 7 // 設定値ですが、変更したら要検証…
            }
        },
        watch: {
            // 親からページ情報を渡される際の処理
            currentPage: function() {
                this.reflesh();
            }, 
            unit: function() {
                this.reflesh();
            },
            postsCount: function() {
                this.reflesh();
            }
        },
        methods: {
            reflesh: function() {
            
                this.pageArray = [];
                var max = this.getLastPage(); // 切り上げ
                
                if( this.currentPage < 1) this.currentPage = 1;

                // ページのリンクを生成

                //    -> ページ数がそもそも少ない場合
                if( max <= this.LINK_COUNT ){
                    for ( var i = this.getFirstPage() ; i <= max; i++ ) {
                        this.pageArray.push(i);
                    }
                    return;
                }

                //    -> ページ数が十分にある場合
                if( 
                    this.currentPage <= Math.ceil( this.LINK_COUNT / 2 ) 
                ) {
                    for ( var i = this.getFirstPage() ; i <= this.LINK_COUNT; i++ ) {
                        if( i > max) break;
                        this.pageArray.push(i);
                    }
                } else if ( 
                    this.currentPage > max - Math.ceil( this.LINK_COUNT / 2 ) 
                ) {
                    for ( var i = (max - this.LINK_COUNT + 1); i <= max; i++ ) {
                        if( i > max) break;
                        this.pageArray.push(i);
                    }
                } else {
                    for ( var i = (this.currentPage - Math.ceil( this.LINK_COUNT / 2 ));
                            i <= (this.currentPage + Math.ceil( this.LINK_COUNT / 2 ));
                            i++ ) {
                        if( i > max) break;
                        this.pageArray.push(i);
                    }
                }

            },
            tapPager: function(event) {
                let $element = $(event.target);
                let $target = this.getTarget($element);
                if ($target.attr('disabled')) return;

                if ($target.hasClass('index')) {
                    let val = $target.text()*1;
                    if (val != this.currentPage)
                        this.$emit('setCurrentPage', val);
                    return;
                }

                let transType = $target.data('type');
                let nextPage =
                    transType == 'first' ? this.getFirstPage():
                    transType == 'prev' ? this.currentPage - 1:
                    transType == 'next' ? this.currentPage + 1:
                    transType == 'last' ? this.getLastPage():
                    this.currentPage;
                if (nextPage != this.currentPage)
                    this.$emit('setCurrentPage', nextPage);
            },
            getTarget: function($element) {
                /*
                 * XXX
                 * icon(vue-fontawesomw)はpager-linkより子の場合は判定用に情報を入れられなさそう?
                 * 仕方ないので親を辿ってpager-linkを持つものを探す
                 * タグ構成や属性依存の処理なので注意。
                 */
                if ($element.get(0) == null) return null;
                return $element.hasClass('pager-link') ? $element : this.getTarget($element.parent());
            },
            isCurrent: function(val) {
                if( val == this.currentPage ) {
                    return true;
                } else {
                    false;
                }
            },
            isFirst: function() {
                if( this.currentPage == 1) {
                    return true;
                } else {
                    false;
                }
            },
            isLast: function() {
                if( this.currentPage == Math.ceil(this.postsCount / this.unit)) {
                    return true;
                } else {
                    false;
                }
            },
            isPrevDisabled: function() {
                if (this.postsCount == 0) return true;
                if (this.isFirst()) return true;
                return false;
            },
            isNextDisabled: function() {
                if (this.postsCount == 0) return true;
                if (this.isLast()) return true;
                return false;
            },
            getFirstPage: function() {
                return 1;
            },
            getLastPage: function() {
                return Math.ceil(this.postsCount / this.unit);
            }
        }
    }
</script>

<style scoped>
    .pager-links {
        text-align: center;
    }
    .pager-link {   
        display: inline-block;
        width: 23px;
        font-size: 12px;
        text-align: center;
        margin: 0px;
        border: solid 1px rgb(168, 168, 168);
    }
    .index {
        width: 20px;
    }
    .left {
        border-radius: 5px 0px 0px 5px;
    }
    .right {
        border-radius: 0px 5px 5px 0px;
    }
    .current {
        background-color: #E0E0E0;
    }
    .disabled {
        color: #CCC;
    }
</style>
