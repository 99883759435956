<template>
    <div class="setting-general setting-general-edit">
        <h1>設定 - 一般 - 編集</h1>
        <div class="card-wrapper gray-border">
            <h3 class="card-title">
                設定値一覧
            </h3>
            <div class="card-body">
                <div class="item">
                    <label>項目名</label>
                    <div class="value">{{ setting.key }}</div>
                </div>
                <div class="item">
                    <label>値</label>
                    <div class="value">
                        <input type="number" v-model="setting.value" >
                    </div>
                </div>
                <div class="item">
                    <label>備考</label>
                    <div class="value">{{ setting.memo }}</div>
                </div>
            </div>
        </div>
        <div class="button_area">
            <a class="btn btn-secondary return" href="/setting/general">戻る</a>
            <a class="btn btn-primary"
               v-if="loading == false"
               v-on:click="updateSetting()">保存</a>
        </div>
    </div>
</template>
<script>
    export default {
        data: function() { 
            return {
                loading: true,
                setting: {
                    id: "",
                    key: "",
                    value: "",
                    memo: ""
                }
            }
        },
        mounted: function() {
            this.getSetting();
        },
        methods: {
            getSetting: function() {
                var document_id = window.location.href.split('/').pop();
                var self = this;
                axios.get("/api/v1/setting/get/general/" + document_id,
                    {}
                ).then(response => {
                    self.setting = response.data.data;
                    self.loading = false;
                }).catch(error => { 
                    self.$toasted.show('データが存在しません。');
                    console.log(error);
                })
            },
            updateSetting: function() {
                var self = this;
                var data = {
                    value: self.setting.value
                };
                axios.put("/api/v1/setting/update/general/" + self.setting.id,
                    data
                ).then(response => {
                    window.location.href = "/setting/general";
                }).catch(error => { 
                    self.$toasted.show('更新に失敗しました。');
                    console.log(error);
                })

            },
        }
    }
</script>
