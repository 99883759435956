var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-wrapper gray-border", attrs: { id: "genres" } },
    [
      _c("h3", { staticClass: "card-title" }, [
        _vm._v(_vm._s(_vm._ct("title.genre_dropdown"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedGenre,
                expression: "selectedGenre",
              },
            ],
            staticClass: "form-control",
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedGenre = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                function ($event) {
                  return _vm.onChange()
                },
              ],
            },
          },
          _vm._l(this.genres, function (genre) {
            return _c("option", { domProps: { value: genre.id } }, [
              _vm._v(
                "\n                " + _vm._s(genre.name) + "\n            "
              ),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }