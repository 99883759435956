var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-body", attrs: { id: "target-student" } },
    [
      _c("div", { staticClass: "simple-setting" }, [
        _c(
          "div",
          {
            staticClass: "root-select",
            class: _vm.selectStudents === "allStudents" ? "active" : "",
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectStudents,
                  expression: "selectStudents",
                },
              ],
              staticClass: "select-students-button form-control",
              attrs: { id: "allStudents", type: "radio", value: "allStudents" },
              domProps: { checked: _vm._q(_vm.selectStudents, "allStudents") },
              on: {
                change: function ($event) {
                  _vm.selectStudents = "allStudents"
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "select-targrt-students-label",
                attrs: { for: "allStudents" },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm._ct("title.simple_setting")) +
                    "\n            "
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "items simple-items",
            class: _vm.selectStudents === "allStudents" ? "" : "disable-target",
          },
          _vm._l(_vm.simple_items, function (simple_item, index) {
            return _c("div", { staticClass: "simple-item" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: simple_item.value,
                    expression: "simple_item.value",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: simple_item.id,
                  type: "checkbox",
                  disabled: _vm.selectStudents !== "allStudents",
                },
                domProps: {
                  value: index,
                  checked: Array.isArray(simple_item.value)
                    ? _vm._i(simple_item.value, index) > -1
                    : simple_item.value,
                },
                on: {
                  change: function ($event) {
                    var $$a = simple_item.value,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = index,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(simple_item, "value", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            simple_item,
                            "value",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(simple_item, "value", $$c)
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "simple-label", attrs: { for: simple_item.id } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._ct(
                          "item.inputlabel.target_select." + simple_item.id
                        )
                      ) +
                      "\n                "
                  ),
                ]
              ),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "detail-setting" }, [
        _c(
          "div",
          {
            staticClass: "root-select",
            class: _vm.selectStudents === "selectDetail" ? "active" : "",
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectStudents,
                  expression: "selectStudents",
                },
              ],
              staticClass: "select-students-button form-control",
              attrs: {
                id: "selectDetail",
                type: "radio",
                value: "selectDetail",
              },
              domProps: { checked: _vm._q(_vm.selectStudents, "selectDetail") },
              on: {
                change: function ($event) {
                  _vm.selectStudents = "selectDetail"
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "select-targrt-students-label",
                attrs: { for: "selectDetail" },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm._ct("title.detail_setting")) +
                    "\n            "
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "items detail-items",
            class: _vm.selectStudents === "allStudents" ? "disable-target" : "",
          },
          [
            _c(
              "div",
              { staticClass: "select-detail" },
              [
                _c("target-student-item-label", {
                  attrs: { titlekey: "item.inputlabel.target_select.degree" },
                }),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.belongs.degree_id,
                        expression: "belongs.degree_id",
                      },
                    ],
                    staticClass: "select-item form-control",
                    attrs: { disabled: _vm.belongs.selectedDegree },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.belongs,
                            "degree_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.onSelectedGetDegreeId,
                      ],
                    },
                  },
                  [
                    _vm.isLoading.lv1
                      ? _c("option", { attrs: { value: "" } }, [
                          _vm._v("Loading…"),
                        ])
                      : _c("option", { attrs: { value: "" } }, [_vm._v("--")]),
                    _vm._v(" "),
                    _vm._l(_vm.belongs.level_1_data, function (degree) {
                      return _c(
                        "option",
                        { key: degree.code, domProps: { value: degree.code } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(degree.name) +
                              "\n                    "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "select-detail" },
              [
                _c("target-student-item-label", {
                  attrs: { titlekey: "item.inputlabel.target_select.school" },
                }),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.belongs.root_id,
                        expression: "belongs.root_id",
                      },
                    ],
                    staticClass: "select-item form-control",
                    attrs: { disabled: _vm.belongs.selectedFaculty },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.belongs,
                            "root_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.onSelectedGetRootId,
                      ],
                    },
                  },
                  [
                    _vm.isLoading.lv2
                      ? _c("option", { attrs: { value: "" } }, [
                          _vm._v("Loading…"),
                        ])
                      : _c("option", { attrs: { value: "" } }, [_vm._v("--")]),
                    _vm._v(" "),
                    _vm._l(_vm.belongs.level_2_data, function (faculty) {
                      return _c(
                        "option",
                        {
                          key: faculty.code,
                          domProps: { value: faculty.code },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(faculty.name) +
                              "\n                    "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "select-detail" },
              [
                _c("target-student-item-label", {
                  attrs: { titlekey: "item.inputlabel.target_select.division" },
                }),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.belongs.child_id,
                        expression: "belongs.child_id",
                      },
                    ],
                    staticClass: "select-item form-control",
                    attrs: { disabled: _vm.belongs.selectedDepartment },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.belongs,
                            "child_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.onSelectedGetChildrenId,
                      ],
                    },
                  },
                  [
                    _vm.isLoading.lv4
                      ? _c("option", { attrs: { value: "" } }, [
                          _vm._v("Loading…"),
                        ])
                      : _c("option", { attrs: { value: "" } }, [_vm._v("--")]),
                    _vm._v(" "),
                    _vm._l(_vm.belongs.level_4_data, function (department) {
                      return _c(
                        "option",
                        {
                          key: department.code,
                          domProps: { value: department.code },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(department.name) +
                              "\n                    "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "select-detail" },
              [
                _c("target-student-item-label", {
                  attrs: { titlekey: "item.inputlabel.target_select.course" },
                }),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.belongs.gchild_id,
                        expression: "belongs.gchild_id",
                      },
                    ],
                    staticClass: "select-item form-control",
                    attrs: { disabled: _vm.belongs.selectedCourse },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.belongs,
                            "gchild_id",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.onSelectedGetGChildrenId,
                      ],
                    },
                  },
                  [
                    _vm.isLoading.lv5
                      ? _c("option", { attrs: { value: "" } }, [
                          _vm._v("Loading…"),
                        ])
                      : _c("option", { attrs: { value: "" } }, [_vm._v("--")]),
                    _vm._v(" "),
                    _vm._l(_vm.belongs.level_5_data, function (course) {
                      return _c(
                        "option",
                        { key: course.code, domProps: { value: course.code } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(course.name) +
                              "\n                    "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "select-detail grade-items" },
              [
                _c("target-student-item-label", {
                  attrs: {
                    titlekey: "item.inputlabel.target_select.grade",
                    required: true,
                  },
                }),
                _vm._v(" "),
                _vm._l([1, 2, 3, 4, 5, 6], function (g) {
                  return _c("div", { staticClass: "grade-item" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.belongs.target_grades,
                          expression: "belongs.target_grades",
                        },
                      ],
                      staticClass: "grade form-control",
                      attrs: {
                        id: "grade" + g,
                        type: "checkbox",
                        disabled: _vm.belongs.selected_target_grades,
                      },
                      domProps: {
                        value: g,
                        checked: Array.isArray(_vm.belongs.target_grades)
                          ? _vm._i(_vm.belongs.target_grades, g) > -1
                          : _vm.belongs.target_grades,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.belongs.target_grades,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = g,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.belongs,
                                  "target_grades",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.belongs,
                                  "target_grades",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.belongs, "target_grades", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "grade-label",
                        attrs: { for: "grade" + g },
                      },
                      [_vm._v(_vm._s(g) + "年")]
                    ),
                  ])
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "select-detail status-items" },
              [
                _c("target-student-item-label", {
                  attrs: {
                    titlekey: "item.inputlabel.target_select.status",
                    required: true,
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "status-item" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.belongs.target_status,
                        expression: "belongs.target_status",
                      },
                    ],
                    staticClass: "status form-control",
                    attrs: {
                      id: "status1",
                      type: "checkbox",
                      value: "regular",
                      disabled: _vm.belongs.selected_target_status,
                    },
                    domProps: {
                      checked: Array.isArray(_vm.belongs.target_status)
                        ? _vm._i(_vm.belongs.target_status, "regular") > -1
                        : _vm.belongs.target_status,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.belongs.target_status,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "regular",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.belongs,
                                "target_status",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.belongs,
                                "target_status",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.belongs, "target_status", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "status-label", attrs: { for: "status1" } },
                    [_vm._v("正規")]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "status-item" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.belongs.target_status,
                        expression: "belongs.target_status",
                      },
                    ],
                    staticClass: "status form-control",
                    attrs: {
                      id: "status2",
                      type: "checkbox",
                      value: "other",
                      disabled: _vm.belongs.selected_target_status,
                    },
                    domProps: {
                      checked: Array.isArray(_vm.belongs.target_status)
                        ? _vm._i(_vm.belongs.target_status, "other") > -1
                        : _vm.belongs.target_status,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.belongs.target_status,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "other",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.belongs,
                                "target_status",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.belongs,
                                "target_status",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.belongs, "target_status", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "status-label", attrs: { for: "status2" } },
                    [_vm._v("非正規")]
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "add-button" }, [
              _c(
                "button",
                {
                  staticClass: "submit btn btn-primary",
                  attrs: {
                    type: "button",
                    disabled: _vm.belongs.addTargetBelongsButton,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addTargetBelongs()
                    },
                  },
                },
                [_vm._v("追加 / Add")]
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "add-notice" }, [
              _vm._v(
                ' ※条件を指定の上、追加ボタンを押してください / Please specify your requirements and click the "Add" button.'
              ),
            ]),
            _vm._v(" "),
            _vm.target_belongs_original.length > 0
              ? _c(
                  "ul",
                  {
                    staticClass: "added-belongs-area",
                    class:
                      _vm.selectStudents === "allStudents"
                        ? "disable-list"
                        : "",
                  },
                  _vm._l(
                    _vm.target_belongs_original,
                    function (target_belongs_data, index) {
                      return _c(
                        "li",
                        { key: index },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.makeBelongTargetName(target_belongs_data)
                              ) +
                              "\n                    "
                          ),
                          _vm._l(target_belongs_data.grades, function (grade) {
                            return _c("span", [
                              _vm._v(_vm._s(" " + grade) + "年"),
                            ])
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.makeBelongStatusName(target_belongs_data)
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "far fa-times-circle",
                            on: {
                              click: function ($event) {
                                return _vm.deleteTargetBelong(index)
                              },
                            },
                          }),
                        ],
                        2
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }