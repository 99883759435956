<template>
    <div class="setting-genre setting-genre-edit">
        <h1>設定 - ジャンル - 編集</h1> 
        <div v-if="errors.length > 0" class="alert alert-danger">
            <ul class="error_ul"><li v-for="error in errors"> {{ error }} </li></ul>
        </div>
        <div class="card-wrapper gray-border">
            <h3 class="card-title">
                設定値一覧
            </h3>
            <div class="card-body">
                <div class="item">
                    <label>表示順</label>
                    <div class="value">
                        <input type="number" v-model="genre.no" >
                    </div>
                </div>
                <div class="item">
                    <label>ジャンル名</label>
                    <div class="value">
                        <input type="text" v-model="genre.name" >
                    </div>
                </div>
            </div>
        </div>
        <div class="button_area">
            <a class="btn btn-secondary return" href="/setting/genre">戻る</a>
            <button class="btn btn-danger danger" 
                v-if="loading == false"
                v-on:click="deleteGenre()">削除</button>
            <button class="btn btn-primary save" 
                v-if="loading == false"
                v-on:click="updateGenre()">保存</button>
        </div>
    </div>
</template>
<script>
    export default {
        data: function() { 
            return {
                errors: [],
                loading: true,
                genre: {
                    id: "",
                    genre_name: "",
                    genre_no: "",
                }
            }
        },
        mounted: function() {
            this.getGenre();
        },
        methods: {
            getGenre: function() {
                var document_id = window.location.href.split('/').pop();
                var self = this;
                axios.get("/api/v1/get/genre/" + document_id, {
                }).then(response => {
                    self.genre = response.data.data;
                    self.loading = false;
                }).catch(error => { 
                    self.$toasted.show('データが存在しません。');
                    console.log(error);
                })
            },
            updateGenre: function() {
                var self = this;
                var data = {
                    no: self.genre.no,
                    name: self.genre.name
                };
                axios.put("/api/v1/update/genre/" + self.genre.id,
                    data
                ).then(response => {
                    if(response.data.status == "SUCCESS") {
                        window.location.href = "/setting/genre";
                    } else {
                        self.errors = response.data.message;
                        window.scrollTo(0, 0);
                    }
                }).catch(error => { 
                    self.$toasted.show('更新に失敗しました。');
                    console.log(error);
                })
 
            },
            deleteGenre: function() {
                var self = this;

                var result = window.confirm('削除したジャンルの記事は閲覧できなくなります。\n削除してよろしいですか？');

                if( result ) {

                    axios.delete("/api/v1/delete/genre/" + self.genre.id, {}
                    ).then(response => {
                        window.location.href = "/setting/genre";
                    }).catch(error => { 
                        self.$toasted.show('削除に失敗しました。');
                        console.log(error);
                    })

                }

            },
        }
    }
</script>
