<template>
    <label class="item-label">
        {{ title_ja() }}
        <span class="required" v-if="required"> ({{ required_ja() }})</span>
        / <br>
        {{ title_en() }}
        <span class="required" v-if="required"> ({{ required_en() }})</span>
    </label>
</template>

<script>
export default {
    props: {
        titlekey:{type:String,required:true},
        required:{type:Boolean,default:false},
    },
    data: function() {
        return {
        }
    },
    methods: {
        title_ja: function() {
            return this.$t(this.titlekey,'ja');
        },
        title_en: function() {
            return this.$t(this.titlekey,'en');
        },
        required_ja: function() {
            return this.$t('notice.required','ja');
        },
        required_en: function() {
            return this.$t('notice.required','en');
        },
    }
}
</script>
