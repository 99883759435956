var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "setting-index" }, [
      _c("h1", [_vm._v("設定")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _c("a", { attrs: { href: "/setting/general" } }, [
            _vm._v("一般設定"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "/setting/genre" } }, [
            _vm._v("ジャンル編集"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }