export default {
    methods: {
        error_proc: function(error,emsgid) {
            console.error(error.response);
            if (error.response.status == 401) {
                location.reload(true);
            } else {
                this.$toasted.show(this._ct(`message.error.${emsgid}`));
            }
        },
        get_all_genre_list: async function() {
            try {
                const resp = await axios.get('/api/v1/get/genres',{});
                return resp.data.data;
            } catch(error) {
                this.error_proc(error,'e001');
                return null;
            }
        },
        get_all_belongs_list: async function() {
            try {
                const resp = await axios.get('/api/v1/get/belongs',{});
                return resp.data.data;
            } catch(error) {
                this.error_proc(error,'e006');
                return null;
            }
        },
        get_belong_list: async function(args) {
            try {
                const resp = await axios.get('/api/v1/get/belonglist',{params:args});
                return resp.data.data;
            } catch(error) {
                this.error_proc(error,'e006');
                return null;
            }
        },
        mark_favorite: async function(post_id) {
            try {
                const resp = await axios.post("/api/v1/general/mark/fav", {post_id: post_id});
                return true;
            } catch(error) {
                this.error_proc(error,'e004');
                return false;
            }
        },
        remove_favorite: async function(post_id) {
            try {
                const resp = await axios.post("/api/v1/general/remove/fav", {post_id: post_id});
                return true;
            } catch(error){
                this.error_proc(error,'e005');
                return false;
            }
        },
        get_post: async function(post_id) {
            try {
                const resp = await axios.get(`/api/v1/general/get/post/${post_id}`,{params:{withEditorInfo:false}});
                const respdata = resp.data;
                if (respdata.status == 'ERROR') {
                    this.$toasted.show(this._ct('message.error.e008'));
                    return null;
                }
                return respdata.data;
            } catch(error){
                this.error_proc(error,'e008');
                return null;
            }
        },
        get_edit_post: async function(post_id) {
            try {
                const resp = await axios.get(`/api/v1/general/get/post/${post_id}`,{params:{withEditorInfo:true}});
                const respdata = resp.data;
                if (respdata.status == 'ERROR') {
                    this.$toasted.show(this._ct('message.error.e008'));
                    return null;
                }
                return respdata.data;
            } catch(error){
                this.error_proc(error,'e008');
                return null;
            }
        },
        get_usertype: async function() {
            try {
                const resp = await axios.get('/api/v1/general/get/usertype',{});
                const respdata = resp.data;
                return respdata.data;
            } catch (error) {
                this.error_proc(error,'e012');
                return null;
            }
        },
        get_post_list: async function(params) {
            try {
                const resp = await axios.get('/api/v1/general/get/posts',{params:params});
                const respdata = resp.data;
                return respdata.data;
            } catch(error) {
                this.error_proc(error,'e002');
                return [];
            }
        },
        upload_file: async function(id,form_params,config) {
            try {
                const resp = await axios.post(`/api/v1/general/upload/${id}`, form_params, config);
                if (resp.status != 200) {
                    this.$toasted.show(this._ct('message.error.upload_error'));
                    return null;
                }
                const respdata = resp.data;
                return respdata.data;
            } catch(error) {
                this.error_proc(error,'upload_error');
                return null;
            }
        },
        create_post: async function(post_data) {
            try {
                const resp = await axios.post('/api/v1/general/create/posts', post_data);
                const respdata = resp.data;
                // 他のメソッドと戻すデータの階層を変えているので注意。
                // ※戻りにのエラーメッセージ等の処理があるため
                return respdata;
            } catch(error) {
                this.error_proc(error,'e010');
                return null;
            }
        },
        update_post: async function(post_id,post_data) {
            try {
                const resp = await axios.put(`/api/v1/general/update/post/${post_id}`, post_data);
                const respdata = resp.data;
                // 他のメソッドと戻すデータの階層を変えているので注意。
                // ※戻りにのエラーメッセージ等の処理があるため
                return respdata;
            } catch(error) {
                this.error_proc(error,'e010');
                return null;
            }
        },
        get_attachment_settings: async function() {
            try {
                const resp = await axios.get('/api/v1/setting/get/attachmentsettings',{});
                const respdata = resp.data;
                return respdata.data;
            } catch(error) {
                this.error_proc(error,'e013');
                return null;
            }
        },
    }
};
