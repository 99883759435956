<template>
    <div id="create-post">
        <h1>全学掲示板：新規投稿 / New Posting to Bulletin Board</h1>
        <div v-if="errors.length > 0" class="alert alert-danger">
            <ul class="error_ul"><li v-for="error in errors"> {{ error }} </li></ul>
        </div>
        <form @submit.prevent="createPost">

            <div class="belong-item card-wrapper gray-border">
                <form-title titlekey="title.target_student" :required="true"></form-title>
                <target-student ref="targetStudent"></target-student>
            </div>

            <div class="item card-wrapper gray-border">
              <form-title titlekey="title.genre_dropdown" :required="true"></form-title>
              <genre-select ref="genre"></genre-select>
            </div>

            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.title" :required="true"></form-title>
                <div class="card-body">
                    <input class="form-control" type="text" v-model="title" maxlength="100">
                </div>
            </div>
            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.body" :required="true"></form-title>
                <div class="card-body">
                    <textarea class="form-control"
                        type="text"
                        v-model="body"
                        rows="8"
                        placeholder="テキストでご入力ください / Enter as text"
                        autocomplete="URL"
                        maxlength="10000"></textarea>
                </div>
            </div>
            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.attachment" :required="false"></form-title>
                <upload-file :id="temp_id" :update="false" ref="uploadfile"></upload-file>
            </div>
            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.period" :required="true"></form-title>
                <div class="card-body center">
                    <input class="form-control datetime" type="datetime-local"
                           min="2000-01-01T00:00" max="2037-12-31T23:59"
                           v-model="publish_from">
                    <span class="unit">～</span>
                    <input class="form-control datetime" type="date"
                           min="2000-01-01" max="2037-12-31"
                           v-model="publish_to_date">
                    <select class="form-control time" v-model="publish_to_hour">
                        <option v-for="time in optionTimes" :key="time.id">{{ time }}</option>
                    </select>
                    <span class="unit">：</span>
                    <select class="form-control time" v-model="publish_to_minutes">
                        <option v-for="time in optionMinutes" :key="time.id">{{ time }}</option>
                    </select>
                </div>
            </div>
            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.contributor" :required="true"></form-title>
                <div class="card-body">
                    <input class="form-control" type="text" v-model="created_user_name" maxlength="512">
                </div>
            </div>
            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.contact" :required="false"></form-title>
                <div class="card-body">
                    <input class="form-control" type="text" v-model="contact_mail" maxlength="512">
                </div>
            </div>
            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.mail" :required="false" forkey="flg_mail"></form-title>
                <send-mail id="flg_mail" ref="sendmail"></send-mail>
            </div>
            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.editor" :required="false" forkey="editor"
                            descr="(ex. user1@office.gs.chiba-u.jp,user2@office.gs.chiba-u.jp)"
                ></form-title>
                <div class="card-body">
                    <input id="editor" class="form-control" type="text" v-model="editor">
                </div>
            </div>
            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.draft" :required="false" forkey="isDraft"></form-title>
                <div class="card-body">
                    <input id="isDraft" class="form-control is-draft" type="checkbox" v-model="is_draft">
                    <span class="is-draft-attention">(下書き時はメール送信されません / Email will not be sent when drafting)</span>
                </div>
            </div>
            <div class="operation-area">
                <button :disabled="submit" class="submit btn btn-primary" type="submit" >登録 / Register</button>
            </div>
        </form>
    </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            isUploading: 'general/isUploading',
        }),
    },
    data: function() {
        return {
            temp_id: this.$uuid.v4(),
            errors: [],
            title: "",
            body: "",
            created_user_name: "",
            contact_mail: "",
            publish_from: moment().format('YYYY-MM-DDT00:00'),
            publish_to: "",
            publish_to_date: "",
            publish_to_hour: "23",
            publish_to_minutes: "59",
            optionTimes: ["00","01","02","03","04","05","06","07",
                          "08","09","10","11","12","13","14","15",
                          "16","17","18","19","20","21","22","23"],
            optionMinutes: [],
            is_draft: false,
            editor: "",
            submit: false,
            isLoading: true,
        }
    },
    mounted: function() {
        this.createOptionMinutes();
    },
    methods: {
        getTomorrow(){
            var tomorrow = new Date();
            return tomorrow.setDate(tomorrow.getDate() + 1);
        },
        makePostData: function() {
            this.publish_to = "";
            if(this.publish_to_date != "") {
                this.publish_to = moment(this.publish_to_date).format('YYYY-MM-DD') + "T" + this.publish_to_hour + ":" + this.publish_to_minutes;
            }
            return {
                temp_id                 : this.temp_id,
                title                   : this.title,
                body                    : this.body,
                created_user_name       : this.created_user_name,
                contact_mail            : this.contact_mail,
                publish_from            : this.publish_from,
                publish_to              : this.publish_to,
                flg_mail                : this.$refs.sendmail.getSendMailFlag(),
                mail_send_datetime      : this.$refs.sendmail.getSendMailDatetimeFormattedString(),
                target_belongs          : this.$refs.targetStudent.getTargetStudentInfo(),
                attachments             : this.$refs.uploadfile.getFileInfo(),
                is_draft                : this.is_draft,
                genre_ref               : this.$refs.genre.getSelected(),
                editor                  : this.editor
            }
        },
        //記事の投稿処理
        createPost: async function() {
            if (this.isUploading) {
                this.$toasted.show(this._ct('message.info.wait_uploading'));
                return;
            }
            this.submit = true;
            const resp = await this.create_post(this.makePostData());
            if (resp != null) {
                if(resp.status == 'SUCCESS') {
                    location.href = this.makeTopPath();
                } else {
                    this.errors = resp.message;
                    window.scrollTo(0, 0);
                }
            }
            this.submit = false;
        },
        createOptionMinutes() {
            for(var minutes = 0; minutes < 60; minutes++) {
                if(minutes < 10){
                    this.optionMinutes.push("0" + String(minutes));
                } else {
                    this.optionMinutes.push(String(minutes));
                }
            }
        },
        //拡張子抽出1つ目の要素にファイル名。2つ目の要素に拡張子を入れて返す
        splitExt(filename) {
            return filename.split(/\.(?=[^.]+$)/);
        },
    },
}
</script>
