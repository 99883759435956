var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "upload-file card-body" }, [
    _vm.isUploading
      ? _c(
          "div",
          {
            staticClass: "drag-and-drop-area",
            on: {
              dragover: function ($event) {
                $event.preventDefault()
              },
              drop: function ($event) {
                $event.preventDefault()
                return _vm.alertDropFile.apply(null, arguments)
              },
            },
          },
          [
            _c("i", {
              staticClass: "far fa-file",
              staticStyle: { "margin-right": "5px" },
            }),
            _vm._v(
              "\r\n    " +
                _vm._s(_vm.uploadingFilename) +
                "をアップロード中です... / " +
                _vm._s(_vm.uploadingFilename) +
                " is being uploaded...\r\n  "
            ),
          ]
        )
      : _c(
          "div",
          {
            staticClass: "drag-and-drop-area",
            on: {
              dragover: function ($event) {
                $event.preventDefault()
              },
              drop: function ($event) {
                $event.preventDefault()
                return _vm.dropFile.apply(null, arguments)
              },
            },
          },
          [
            _c("i", {
              staticClass: "far fa-file",
              staticStyle: { "margin-right": "5px" },
            }),
            _vm._v("ファイルをドロップしてください / Drop in the file\r\n  "),
          ]
        ),
    _vm._v(" "),
    _c("div", { staticClass: "file-list-area" }, [
      _c(
        "ul",
        { staticClass: "file-list-wrapper" },
        _vm._l(_vm.attachments, function (inf, index) {
          return _c("li", { key: inf.id, staticClass: "file-list" }, [
            _vm._v(
              "\r\n        " +
                _vm._s(inf.name) +
                " (" +
                _vm._s(_vm.byte_to_kilobyte(inf.size) + "KB") +
                ")\r\n        "
            ),
            _c("i", {
              staticClass: "far fa-times-circle",
              on: {
                click: function ($event) {
                  return _vm.deleteFile(index, inf.name)
                },
              },
            }),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.warning_files_comment.length > 0
        ? _c("div", { staticClass: "warning-comment" }, [
            _c(
              "ul",
              _vm._l(_vm.warning_files_comment, function (comment) {
                return _c("li", [_vm._v(" " + _vm._s(comment) + " ")])
              }),
              0
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }