/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Axios from 'axios';

import Vue from 'vue';
window.$ = window.jQuery = require('jquery');

// font-awesomeの追加
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);
Vue.component('fa-icon', FontAwesomeIcon);

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: 'ja',
    messages: {
        ja: require('./lang/ja/view.js')(),
        en: require('./lang/en/view.js')(),
    }
});

import mixin_common from './mixins/common';
import mixin_api from './mixins/api';
import mixin_lang from './mixins/lang';
import mixin_localstorage from './mixins/localstorage';
import mixin_html from './mixins/html';
Vue.mixin(mixin_common);
Vue.mixin(mixin_api);
Vue.mixin(mixin_lang);
Vue.mixin(mixin_localstorage);
Vue.mixin(mixin_html);

var TOAST_OPTIONS = {
    position: 'bottom-center',
    duration: 5000,
    fullWidth: true,
    type: 'error'
};

import Toasted from 'vue-toasted';
Vue.use(Toasted,TOAST_OPTIONS);

import UUID from "vue-uuid";
Vue.use(UUID);

/* import Vuex from 'vuex' */
/* Vue.use(Vuex) */
import store from './store/index';

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('maintenance-message',
              require('../common/js/components/partials/MaintenanceMessage.vue').default);

// 共通部品
Vue.component('genre-dropdown',    require('./components/common/GenreDropdown.vue').default);
Vue.component('pager-links',       require('./components/common/PagerLinks.vue').default);
Vue.component('pager-filter',      require('./components/common/PagerFilter.vue').default);
Vue.component('pager-label',       require('./components/common/PagerLabel.vue').default);
Vue.component('genre-select',      require('./components/common/GenreSelect.vue').default);
Vue.component('laravel-paginator', require('./components/common/LaravelPaginator.vue').default);
Vue.component('upload-file',       require('./components/general/partials/UploadFile.vue').default);
Vue.component('target-student',    require('./components/general/partials/TargetStudent.vue').default);
Vue.component('form-title',        require('./components/general/partials/FormTitle.vue').default);
Vue.component('send-mail',         require('./components/general/partials/SendMail.vue').default);
Vue.component('target-student-item-label',
              require('./components/general/partials/TargetStudentItemLabel.vue').default);

// 全学掲示板用 Rootファイル
Vue.component('general-home',        require('./components/general/BBSIndex.vue').default);
Vue.component('general-search',      require('./components/general/BBSSearch.vue').default);
Vue.component('general-detail',      require('./components/general/BBSDetail.vue').default);
Vue.component('general-edit',        require('./components/general/BBSEdit.vue').default);
Vue.component('general-create',      require('./components/general/BBSCreate.vue').default);
Vue.component('general-list',        require('./components/general/BBSList.vue').default);

// マスタ系
Vue.component('setting-home',         require('./components/setting/SettingIndex.vue').default);
Vue.component('setting-genre-home',   require('./components/setting/SettingGenre.vue').default);
Vue.component('setting-genre-create', require('./components/setting/SettingGenreCreate.vue').default);
Vue.component('setting-genre-edit',   require('./components/setting/SettingGenreEdit.vue').default);
Vue.component('setting-general-home', require('./components/setting/SettingGeneral.vue').default);
Vue.component('setting-general-edit', require('./components/setting/SettingGeneralEdit.vue').default);

// Vue.component('general-bbs', require('./components/general/BBS.vue').default);
// Vue.component('general-create-belongs', require('./components/general/BBSCreateBelongs.vue').default);

const ax = Axios.create({withCredentials: true});
ax.interceptors.response.use(function(resp){
    return resp;
},function (error){
    let status = error.response.status;
    let msg = error.response.data.message + '(' + status + ')';
    // Vue.toasted.error(msg);
    return Promise.reject(error);
});

window.axios = ax;

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    i18n,
    store,
});
