var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "edit-post" } }, [
    _c("h1", [_vm._v("全学掲示板：編集 / Edit")]),
    _vm._v(" "),
    _vm.errors.length > 0
      ? _c("div", { staticClass: "alert alert-danger" }, [
          _c(
            "ul",
            { staticClass: "error_ul" },
            _vm._l(_vm.errors, function (error) {
              return _c("li", [_vm._v(" " + _vm._s(error) + " ")])
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.updatePost.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "belong-item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: { titlekey: "title.target_student", required: true },
            }),
            _vm._v(" "),
            _c("target-student", { ref: "targetStudent" }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: { titlekey: "title.genre_dropdown", required: true },
            }),
            _vm._v(" "),
            _c("genre-select", { ref: "genre" }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: { titlekey: "title.title", required: true },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.postData.title,
                    expression: "postData.title",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", maxlength: "100" },
                domProps: { value: _vm.postData.title },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.postData, "title", $event.target.value)
                  },
                },
              }),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: { titlekey: "title.body", required: true },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.postData.body,
                    expression: "postData.body",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  rows: "8",
                  placeholder: "テキストでご入力ください / Enter as text",
                  autocomplete: "URL",
                  maxlength: "10000",
                },
                domProps: { value: _vm.postData.body },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.postData, "body", $event.target.value)
                  },
                },
              }),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: { titlekey: "title.attachment", required: false },
            }),
            _vm._v(" "),
            _c("upload-file", {
              ref: "uploadfile",
              attrs: { id: _vm.attachment_prefix, update: true },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: { titlekey: "title.period", required: true },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "card-body center" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formatted_publish_from,
                    expression: "formatted_publish_from",
                  },
                ],
                staticClass: "form-control datetime",
                attrs: {
                  type: "datetime-local",
                  min: "2000-01-01T00:00",
                  max: "2037-12-31T23:59",
                },
                domProps: { value: _vm.formatted_publish_from },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.formatted_publish_from = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "unit" }, [_vm._v("～")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formatted_publish_to,
                    expression: "formatted_publish_to",
                  },
                ],
                staticClass: "form-control datetime",
                attrs: { type: "date", min: "2000-01-01", max: "2037-12-31" },
                domProps: { value: _vm.formatted_publish_to },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.formatted_publish_to = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formatted_publish_to_hour,
                      expression: "formatted_publish_to_hour",
                    },
                  ],
                  staticClass: "form-control time",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.formatted_publish_to_hour = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.optionTimes, function (time) {
                  return _c("option", { key: time.id }, [_vm._v(_vm._s(time))])
                }),
                0
              ),
              _vm._v(" "),
              _c("span", { staticClass: "unit" }, [_vm._v("：")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formatted_publish_to_minute,
                      expression: "formatted_publish_to_minute",
                    },
                  ],
                  staticClass: "form-control time",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.formatted_publish_to_minute = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.optionMinutes, function (time) {
                  return _c("option", { key: time.id }, [_vm._v(_vm._s(time))])
                }),
                0
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: { titlekey: "title.contributor", required: true },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.postData.contributor_name,
                    expression: "postData.contributor_name",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", maxlength: "512" },
                domProps: { value: _vm.postData.contributor_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.postData,
                      "contributor_name",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: { titlekey: "title.contact", required: false },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.postData.contact_mail,
                    expression: "postData.contact_mail",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", maxlength: "512" },
                domProps: { value: _vm.postData.contact_mail },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.postData, "contact_mail", $event.target.value)
                  },
                },
              }),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: {
                titlekey: "title.mail",
                required: false,
                forkey: "flg_mail",
              },
            }),
            _vm._v(" "),
            _c("send-mail", {
              ref: "sendmail",
              attrs: {
                id: "flg_mail",
                last_noticed_datetime: _vm.getNoticedDatetimeString(),
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: {
                titlekey: "title.editor",
                required: false,
                forkey: "editor",
                descr:
                  "(ex. user1@office.gs.chiba-u.jp,user2@office.gs.chiba-u.jp)",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.editor,
                    expression: "editor",
                  },
                ],
                staticClass: "form-control",
                attrs: { id: "editor", type: "text" },
                domProps: { value: _vm.editor },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.editor = $event.target.value
                  },
                },
              }),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: {
                titlekey: "title.draft",
                required: false,
                forkey: "isDraft",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.postData.is_draft,
                    expression: "postData.is_draft",
                  },
                ],
                staticClass: "form-control is-draft",
                attrs: { id: "isDraft", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.postData.is_draft)
                    ? _vm._i(_vm.postData.is_draft, null) > -1
                    : _vm.postData.is_draft,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.postData.is_draft,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.postData, "is_draft", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.postData,
                            "is_draft",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.postData, "is_draft", $$c)
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "is-draft-attention" }, [
                _vm._v(
                  "(下書き時はメール送信されません / Email will not be sent when drafting)"
                ),
              ]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "operation-area" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-close left",
              attrs: { onclick: "window.close();" },
            },
            [_vm._v("閉じる / Close")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-danger danger",
              attrs: { type: "button" },
              on: { click: _vm.deletePost },
            },
            [_vm._v("削除 / Delete")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary register",
              attrs: { disabled: _vm.submit, type: "submit" },
            },
            [_vm._v("内容を更新して登録 / Update the content and register")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }