<template>
    <div id="search">
        <h4 v-on:click="toggle" v-bind:class="{ isOpened: isOpened, isClosed: !isOpened }">
            {{ _ct('title.search_criteria') }}
        </h4>
        <div class="search-inner" v-bind:class="{ '': isOpened, hidden: !isOpened }">
            <div id="genres" class="card-wrapper gray-border">
                <h3 class="card-title">{{ _ct('title.genre_dropdown') }}</h3>
                <genre-select :includeall="true" @selectedGenre="execSearch()"></genre-select>
            </div>
            <div class="card-wrapper gray-border">
                <h3 class="card-title">{{ _ct('title.search_words') }}</h3>
                <div class="card-body">
                    <input type="text"
                           class="form-control"
                           :placeholder="_ct('placeholder.search_words')"
                           v-model="searchWord">
                </div>
            </div>
            <div class="my-belongs" v-if="isTargetStudent()">
                <div class="check-my-belongs">
                    <input type="checkbox" id="show_my_belongs" name="" v-model="showMyBelongs">
                </div>
                <div class="labels-my-belongs">
                    <label for="show_my_belongs">自分の学部・研究科等向け記事のみ表示 /</label>
                    <label for="show_my_belongs">view posts for my faculty only</label>
                </div>
            </div>
            <div class="item search-exec">
                <button class="btn btn-primary" v-on:click="execSearch()">{{ _ct('button.exec_search') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
 export default {
     props: {
         auth_json_str:{type:String,required:true},
     },
     components: {
     },
     data: function() {
         return {
             auth: null,
             isOpened: true,
             search_word: "",
             genre: "",
             show_my_belongs: true,
         }
     },
     mounted: function() { // 初期化処理
         this.auth = JSON.parse(this.auth_json_str);
         this.showMyBelongs = this.isTargetStudent();
     },
     computed: {
         searchWord: {
             get() {
                 return this.search_word
             },
             set(value) {
                 this.search_word = value
                 this.$emit('inputWord', value);
             }
         },
         showMyBelongs: {
             get() {
                 return this.show_my_belongs;
             },
             set(value) {
                 this.show_my_belongs = value;
                 this.$emit('onChangeShoMyBelongs', value);
             }
         }
     },
     methods: {
         toggle: function(){
             if(this.isOpened) {
                 this.isOpened = false;
             } else {
                 this.isOpened = true;
             }
         },
         execSearch: function(){
             this.$emit('execSearch');
         },
         isTargetStudent: function() {
             if (this.auth == null) return false;
             if (this.auth.is_temporary_student) return false;
             return this.auth.is_student;
         },
     }
 }
</script>
