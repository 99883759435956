<template>
    <div id="edit-post">
        <h1>全学掲示板：編集 / Edit</h1>
        <div v-if="errors.length > 0" class="alert alert-danger">
            <ul class="error_ul"><li v-for="error in errors"> {{ error }} </li></ul>
        </div>
        <form @submit.prevent="updatePost">

            <div class="belong-item card-wrapper gray-border">
                <form-title titlekey="title.target_student" :required="true"></form-title>
                <target-student ref="targetStudent"></target-student>
            </div>

            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.genre_dropdown" :required="true"></form-title>
                <genre-select ref="genre"></genre-select>
            </div>

            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.title" :required="true"></form-title>
                <div class="card-body">
                    <input class="form-control" type="text" v-model="postData.title" maxlength="100">
                </div>
            </div>
            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.body" :required="true"></form-title>
                <div class="card-body">
                    <textarea class="form-control"
                        type="text"
                        v-model="postData.body"
                        rows="8"
                        placeholder="テキストでご入力ください / Enter as text"
                        autocomplete="URL"
                        maxlength="10000"></textarea>
                </div>
            </div>
            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.attachment" :required="false"></form-title>
                <upload-file :id="attachment_prefix"
                               :update="true" ref="uploadfile"></upload-file>
            </div>
            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.period" :required="true"></form-title>
                <div class="card-body center">
                    <input class="form-control datetime" type="datetime-local"
                           min="2000-01-01T00:00" max="2037-12-31T23:59"
                           v-model="formatted_publish_from">
                    <span class="unit">～</span>
                    <input class="form-control datetime" type="date"
                           min="2000-01-01" max="2037-12-31"
                           v-model="formatted_publish_to">
                    <select class="form-control time" v-model="formatted_publish_to_hour">
                        <option v-for="time in optionTimes" :key="time.id">{{ time }}</option>
                    </select>
                    <span class="unit">：</span>
                    <select class="form-control time" v-model="formatted_publish_to_minute">
                        <option v-for="time in optionMinutes" :key="time.id">{{ time }}</option>
                    </select>
                </div>
            </div>
            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.contributor" :required="true"></form-title>
                <div class="card-body">
                    <input class="form-control" type="text" v-model="postData.contributor_name" maxlength="512">
                </div>
            </div>
            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.contact" :required="false"></form-title>
                <div class="card-body">
                    <input class="form-control" type="text" v-model="postData.contact_mail" maxlength="512">
                </div>
            </div>
            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.mail" :required="false" forkey="flg_mail"></form-title>
                <send-mail id="flg_mail"
                           :last_noticed_datetime="getNoticedDatetimeString()"
                           ref="sendmail"></send-mail>
            </div>
            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.editor" :required="false" forkey="editor"
                            descr="(ex. user1@office.gs.chiba-u.jp,user2@office.gs.chiba-u.jp)"
                ></form-title>
                <div class="card-body">
                    <input id="editor" class="form-control" type="text" v-model="editor">
                </div>
            </div>
            <div class="item card-wrapper gray-border">
                <form-title titlekey="title.draft" :required="false" forkey="isDraft"></form-title>
                <div class="card-body">
                    <input id="isDraft" class="form-control is-draft" type="checkbox" v-model="postData.is_draft">
                    <span class="is-draft-attention">(下書き時はメール送信されません / Email will not be sent when drafting)</span>
                </div>
            </div>
            <div class="operation-area">
                <a class="btn btn-close left" onclick="window.close();">閉じる / Close</a>
                <button class="btn btn-danger danger" type="button" @click="deletePost">削除 / Delete</button>
                <button :disabled="submit" class="btn btn-primary register" type="submit" >内容を更新して登録 / Update the content and register</button>
            </div>
        </form>
    </div>
</template>
<script>
    import moment from "moment";
    import { mapGetters } from 'vuex';
    export default {
        computed: {
            ...mapGetters({
                isUploading: 'general/isUploading',
            }),
            formatted_publish_from: {
                get: function() {
                    return this.publish_from.format('YYYY-MM-DDTHH:mm');
                },
                set: function(value) {
                    this.publish_from = moment(value);
                }
            },
            formatted_publish_to: {
                get: function() {
                    if (this.publish_to == null) return null;
                    return this.publish_to.format('YYYY-MM-DD');
                },
                set: function(value) {
                    let m = moment(value);
                    if (this.publish_to == null) {
                        m = m.endOf('day');
                        m.set({hour: this.publish_to_hour, minute: this.publish_to_min});
                        this.publish_to = m;
                        return;
                    }
                    const new_y = m.get('year');
                    const new_m = m.get('month');
                    const new_d = m.get('date');
                    this.publish_to.set({year: new_y, month: new_m, date: new_d});
                },
            },
            formatted_publish_to_hour: {
                get: function() {
                    if (this.publish_to == null) return this.publish_to_hour;
                    return this.publish_to.format('HH');
                },
                set: function(value) {
                    this.publish_to_hour = value;
                    if (this.publish_to != null) {
                        this.publish_to.set('hour',this.publish_to_hour);
                    }
                },
            },
            formatted_publish_to_minute: {
                get: function() {
                    if (this.publish_to == null) return this.publish_to_min;
                    return this.publish_to.format('mm');
                },
                set: function(value) {
                    this.publish_to_min = value;
                    if (this.publish_to != null) {
                        this.publish_to.set('minute',this.publish_to_min);
                    }
                },
            },
        },
        components: {
        },
        data: function() {
            return {
                errors: [],
                postData: {},
                publish_from: moment().startOf('day'),
                publish_to: null,
                mail_send_datetime: null,
                editor:'',
                attachment_prefix: 'dummy',
                filesettings: {},
                selectStudents: "selectDetail",
                addTargetBelongsButton: true,
                optionTimes: ["00","01","02","03","04","05","06","07",
                              "08","09","10","11","12","13","14","15",
                              "16","17","18","19","20","21","22","23"],
                optionMinutes: [],
                warning_files_comment: false,
                submit: false,
                isLoading: false,
                publish_to_hour: '23',
                publish_to_min: '59',
            }
        },
        mounted: function() {
            this.getPost();
            this.createOptionMinutes();
        },
        methods: {
            isEmptyPostData: function() {
                return Object.keys(this.postData).length == 0;
            },
            getPost: async function() {
                const document_id = window.location.href.split('/').pop();
                const data = await this.get_edit_post(document_id);
                this.postData = data;
                this.attachment_prefix = this.postData.attachment_prefix;
                this.formatDateTime();
                this.decodeEditor();

                const sel = this.postData.selection;
                this.$store.dispatch('general/setSelectStudents',{
                    selectStudents: sel.selectStudents});
                this.$store.dispatch('general/setTargetBelongs',{
                    targetBelongs: sel.target_belongs_original});
                const simple_inf = sel.simpleTargetSelections;
                if (simple_inf.length > 0) {
                    this.$store.dispatch('general/setSimpleSelections',simple_inf);
                }
                this.$refs.genre.setGenreId(this.postData.post_genre_id);
                this.$store.dispatch('general/setAttachments',{attachments: this.postData.attachments});
                const sendmail_datetimestr = this.postData.mail_send_datetime;
                if (sendmail_datetimestr != null) {
                    this.$store.dispatch('general/setDatetimeForSendingMail',
                                         moment(sendmail_datetimestr));
                }
                this.$store.dispatch('general/setSendMailFlag',this.postData.flg_mail);
            },
            //日付データを表示用に変換
            formatDateTime: function() {
                this.publish_from = moment().startOf('day');
                this.publish_to = null;
                if (this.postData.publish_from != null) {
                    this.publish_from = moment(this.postData.publish_from);
                }
                if (this.postData.publish_to != null) {
                    this.publish_to = moment(this.postData.publish_to);
                }
                if (this.postData.mail_send_datetime != null) {
                    this.mail_send_datetime = moment(this.postData.mail_send_datetime);
                }
            },
            decodeEditor: function() {
                if (this.postData.editorEmails == null) return;
                if (this.postData.editorEmails.length == 0) return;
                this.editor = this.postData.editorEmails.join(',');
            },
            makeUpdateData: function() {
                return {
                    title: this.postData.title,
                    body: this.postData.body,
                    created_user_name: this.postData.contributor_name,
                    contact_mail: this.postData.contact_mail,
                    publish_from: this.moment_format(this.publish_from),
                    publish_to: this.moment_format(this.publish_to),
                    flg_mail: this.$refs.sendmail.getSendMailFlag(),
                    mail_send_datetime: this.$refs.sendmail.getSendMailDatetimeFormattedString(),
                    target_belongs: this.$refs.targetStudent.getTargetStudentInfo(),
                    attachments: this.$refs.uploadfile.getFileInfo(),
                    attachment_prefix: this.postData.attachment_prefix,
                    is_draft: this.postData.is_draft,
                    genre_ref: this.$refs.genre.getSelected(),
                    editor: this.editor,
                }
            },
            //記事の更新処理
            updatePost: async function() {
                if (this.isUploading) {
                    this.$toasted.show(this._ct('message.info.wait_uploading'));
                    return;
                }
                const post_id = window.location.href.split('/').pop();
                this.submit = true;
                const resp = await this.update_post(post_id,this.makeUpdateData());
                if (resp != null) {
                    if(resp.status == 'SUCCESS') {
                        location.href = this.makeTopPath();
                    } else {
                        this.errors = resp.message;
                        window.scrollTo(0, 0);
                    }
                }
                this.submit = false;
            },
            //投稿記事の削除
            deletePost: function() {
                var result = confirm('本当に削除しますか？');
                if (result) {
                    var document_id = window.location.href.split('/').pop();
                    axios.delete("/api/v1/general/delete/post/" + document_id, {})
                    .then(function(res){
                        if(res.data.status == "SUCCESS") {
                            location.href = '/general';
                        } else {
                            self.errors = res.data.message;
                            window.scrollTo(0, 0);
                        }
                    })
                    .catch(error => { // 失敗時
                        console.log(error);
                    });
                } else {
                  return
                }
            },
            createOptionMinutes() {
                for(var minutes = 0; minutes < 60; minutes++) {
                    if(minutes < 10){
                        this.optionMinutes.push("0" + String(minutes));
                    } else {
                        this.optionMinutes.push(String(minutes));
                    }
                }
            },
            getNoticedDatetimeString: function() {
                const noticedDatetime = this.postData.noticed_datetime;
                if (noticedDatetime == null) return '';
                return this.format_datetime(noticedDatetime,'YYYY/MM/DD HH:mm');
            },
        },
    }
</script>
