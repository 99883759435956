<template>
<div class="upload-file card-body">

  <div v-if="isUploading" class="drag-and-drop-area" @dragover.prevent @drop.prevent="alertDropFile">
    <i class="far fa-file" style="margin-right: 5px;"></i>
    {{ uploadingFilename }}をアップロード中です... / {{ uploadingFilename }} is being uploaded...
  </div>
  <div v-else class="drag-and-drop-area" @dragover.prevent @drop.prevent="dropFile">
    <i class="far fa-file" style="margin-right: 5px;"></i>ファイルをドロップしてください / Drop in the file
  </div>

  <div class="file-list-area">
    <ul class="file-list-wrapper">
      <li class="file-list" v-for="(inf,index) in attachments" :key="inf.id">
        {{ inf.name }} ({{ byte_to_kilobyte(inf.size) + 'KB' }})
        <i class="far fa-times-circle" @click="deleteFile(index,inf.name)"></i>
      </li>
    </ul>
    <div v-if="warning_files_comment.length > 0" class="warning-comment">
      <ul><li v-for="comment in warning_files_comment"> {{ comment }} </li></ul>
    </div>
  </div>
</div>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex';
export default {
    props: {
        id:{type:String,required:true},
        update:{type:Boolean,default:true},
    },
    computed: {
        ...mapGetters({
            attachments: 'general/getAttachments',
            isUploading: 'general/isUploading',
            uploadingFilename: 'general/getUploadingFilename',
        }),
    },
    data() {
        return {
            forbidden_exts: ["exe","com","bat","cmd","vbs","vbe","js","jse", "wsf","wsh","msc","jar",
                             "hta","msi","scr","cpl","lnk","url","iqy"],
            warning_files_comment: [],
            filesettings: null,
        }
    },
    mounted: function() {
        this.getFileSettings();
    },
    methods: {
        getFileSettings: async function() {
            const settings = await this.get_attachment_settings();
            this.filesettings = settings;
        },
        getFileInfo: function() {
            return this.$store.state.general.attachments;
        },
        setFileInfo: function(attachments) {
            this.$store.dispatch('general/setAttachments',{attachments: attachments});
        },
        alertDropFile: function() {
            this.$toasted.show('アップロード処理中です。完了までお待ち下さい。');
        },
        deleteFile: function(index,filename) {
            if (index < 0) return;
            if (this.isUploading) {
                this.$toasted.show('アップロード処理中です。完了までお待ち下さい。');
                return;
            }
            const result = confirm(`${filename}を削除しますか?\n※記事更新時に反映されます`);
            if (!result) return;

            this.warning_files_comment = [];
            const curFiles = this.$store.state.general.attachments;
            if (curFiles.length < index + 1) return;
            curFiles.splice(index,1)
            this.$store.dispatch('general/setAttachments',{attachments: curFiles});
        },
        showErrorMessages: function(errors) {
            errors.forEach(e => {
                const name = e.name;
                const stat = e.error;
                let msg = `${name}の処理中にエラーが発生したため添付できません(${stat})`;
                if (stat == 'invalid') {
                    msg = `${name}は無効なファイルのため添付できません`;
                } else if (stat == 'infected') {
                    msg = `${name}にウィルスが検出されたため添付できません`;
                }
                this.$toasted.show(msg);
            });
        },
        dropFile: async function(event) {
            if (this.filesettings === null) {
                this.$toasted.show('投稿の為に必要な設定値を取得中です。数秒置いてからお試しください。');
                return;
            }

            this.warning_files_comment = [];

            const forbiddenExtStr = this.forbidden_exts.join(',');
            const maxFileCount = this.filesettings.max_count_attachment*1;
            const maxFileSize = this.filesettings.max_size_attachment*1;
            const curFiles = this.$store.state.general.attachments;
            const curFileCount = curFiles.length;
            const curFileNames = curFiles.map(x => x.name);
            const newFiles = event.dataTransfer.files;
            const newFileCount = newFiles.length;
            const config = {headers: {"Content-Type": "multipart/form-data"}};

            if (curFileCount + newFileCount > maxFileCount) {
                this.warning_files_comment.push(`投稿できるファイルは${maxFileCount}つまでです`);
                return;
            }

            const inf = [];
            for (let i=0;i<newFiles.length;i++){
                const params = new FormData();
                const file = newFiles.item(i);
                const name = file.name;
                const size = file.size;
                const ext = name.split(/\.(?=[^.]+$)/)[1];
                // XXX 文言管理
                if (this.forbidden_exts.includes(ext)) {
                    this.warning_files_comment.push(`拡張子が${forbiddenExtStr}のファイルは添付出来ません。` );
                    continue;
                }
                if (curFileNames.includes(name)) {
                    // XXX 名前だけで判定でいいのか?そもそも上書きでいいのでは?
                    this.warning_files_comment.push("既に添付されているファイルが含まれています");
                    continue;
                }
                if (size > maxFileSize << 10) {
                    this.warning_files_comment.push(`投稿できるファイルサイズの上限は${maxFileSize}KBまでです`);
                    continue;
                }
                params.append('file[0]', file);

                this.$store.dispatch('general/setUploading',{filename: name});

                const respdata = await this.upload_file(this.id, params, config);
                if (respdata == null) continue;

                const attached = respdata.attachments;
                const errors = respdata.errors;
                attached.forEach(function(e) {
                    //e.size = this.byte_to_kilobyte(e.size) + 'KB';
                    inf.push(e);
                }.bind(this));
                const updatedFiles = curFiles.concat(inf);
                this.$store.dispatch('general/setAttachments',{attachments: updatedFiles});
                this.showErrorMessages(errors);

                this.$store.dispatch('general/unsetUploading',{});
            }
         },
     },
 }
</script>
