<template>
    <div id="target-student" class="card-body">

        <div class="simple-setting">
            <div class="root-select"
                 v-bind:class="selectStudents === 'allStudents' ? 'active' : ''">
                <input id="allStudents" class="select-students-button  form-control"
                       type="radio" v-model="selectStudents" value="allStudents">
                <label for="allStudents" class="select-targrt-students-label">
                    {{ _ct('title.simple_setting') }}
                </label>
            </div>
            <div class="items simple-items"
                v-bind:class="selectStudents === 'allStudents' ? '' : 'disable-target'">
                <div class="simple-item" v-for="(simple_item,index) in simple_items">
                    <input :id="simple_item.id" class="form-control" type="checkbox"
                           v-model="simple_item.value" :value="index"
                           :disabled="selectStudents !== 'allStudents'">
                    <label :for="simple_item.id" class="simple-label">
                        {{ _ct('item.inputlabel.target_select.' + simple_item.id) }}
                    </label>
                </div>
            </div>
        </div>

        <div class="detail-setting">
            <div class="root-select"
                 v-bind:class="selectStudents === 'selectDetail' ? 'active' : ''">
                <input id="selectDetail" class="select-students-button  form-control"
                       type="radio" v-model="selectStudents" value="selectDetail">
                <label for="selectDetail" class="select-targrt-students-label">
                    {{ _ct('title.detail_setting') }}
                </label>
            </div>

            <div class="items detail-items"
                 v-bind:class="selectStudents === 'allStudents' ? 'disable-target' : ''">

                <div class="select-detail">
                    <target-student-item-label
                        titlekey="item.inputlabel.target_select.degree">
                    </target-student-item-label>
                    <select class="select-item form-control"
                            @change="onSelectedGetDegreeId"
                            v-model="belongs.degree_id"
                            :disabled="belongs.selectedDegree">
                        <option v-if="isLoading.lv1" value="">Loading…</option>
                        <option v-else value="">--</option>
                        <option v-for="degree in belongs.level_1_data"
                                :value="degree.code"
                                :key="degree.code">
                            {{ degree.name }}
                        </option>
                    </select>
                </div>

                <div class="select-detail">
                    <target-student-item-label
                        titlekey="item.inputlabel.target_select.school">
                    </target-student-item-label>
                    <select class="select-item form-control"
                            @change="onSelectedGetRootId"
                            v-model="belongs.root_id"
                            :disabled="belongs.selectedFaculty">
                        <option v-if="isLoading.lv2" value="">Loading…</option>
                        <option v-else value="">--</option>
                        <option v-for="faculty in belongs.level_2_data"
                                :value="faculty.code"
                                :key="faculty.code">
                            {{ faculty.name }}
                        </option>
                    </select>
                </div>
                <div class="select-detail">
                    <target-student-item-label
                        titlekey="item.inputlabel.target_select.division">
                    </target-student-item-label>
                    <select class="select-item form-control"
                            @change="onSelectedGetChildrenId"
                            v-model="belongs.child_id"
                            :disabled="belongs.selectedDepartment">
                        <option v-if="isLoading.lv4" value="">Loading…</option>
                        <option v-else value="">--</option>
                        <option v-for="department in belongs.level_4_data"
                                :value="department.code"
                                :key="department.code">
                            {{ department.name }}
                        </option>
                    </select>
                </div>
                <div class="select-detail">
                    <target-student-item-label
                        titlekey="item.inputlabel.target_select.course">
                    </target-student-item-label>
                    <select
                        class="select-item form-control"
                        @change="onSelectedGetGChildrenId"
                        v-model="belongs.gchild_id"
                        :disabled="belongs.selectedCourse">
                        <option v-if="isLoading.lv5" value="">Loading…</option>
                        <option v-else value="">--</option>
                        <option v-for="course in belongs.level_5_data"
                                :value="course.code"
                                :key="course.code">
                            {{ course.name }}
                        </option>
                    </select>
                </div>
                <div class="select-detail grade-items">
                    <target-student-item-label
                        titlekey="item.inputlabel.target_select.grade"
                        :required="true"
                    >
                    </target-student-item-label>
                    <div class="grade-item" v-for="g in [1,2,3,4,5,6]">
                        <input :id="'grade' + g" class="grade form-control" type="checkbox"
                               v-model="belongs.target_grades" :value="g"
                               :disabled="belongs.selected_target_grades">
                        <label :for="'grade' + g" class="grade-label">{{ g }}年</label>
                    </div>
                </div>

                <div class="select-detail status-items">
                    <target-student-item-label
                        titlekey="item.inputlabel.target_select.status"
                        :required="true"
                    >
                    </target-student-item-label>
                    <div class="status-item">
                        <input id="status1" class="status form-control" type="checkbox"
                               v-model="belongs.target_status" value="regular"
                               :disabled="belongs.selected_target_status">
                        <label for="status1" class="status-label">正規</label>
                    </div>
                    <div class="status-item">
                        <input id="status2" class="status form-control" type="checkbox"
                               v-model="belongs.target_status" value="other"
                               :disabled="belongs.selected_target_status">
                        <label for="status2" class="status-label">非正規</label>
                    </div>
                </div>

                <div class="add-button">
                    <button class="submit btn btn-primary"
                            type="button"
                            :disabled="belongs.addTargetBelongsButton"
                            @click="addTargetBelongs()">追加 / Add</button>
                </div>

                <p class="add-notice"> ※条件を指定の上、追加ボタンを押してください / Please specify your requirements and click the "Add" button.</p>
                <ul class="added-belongs-area" v-if="target_belongs_original.length > 0"
                    v-bind:class="selectStudents === 'allStudents' ? 'disable-list' : ''">
                    <li v-for="(target_belongs_data, index) in target_belongs_original"
                        v-bind:key="index">
                        {{ makeBelongTargetName(target_belongs_data) }}
                        <span v-for=" grade in target_belongs_data.grades">{{ " " + grade }}年</span>
                        <span>{{ makeBelongStatusName(target_belongs_data) }}</span>
                        <i class="far fa-times-circle" @click="deleteTargetBelong(index)"></i>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
 import {mapGetters,mapMutations} from 'vuex';
 export default {
     computed: {
         ...mapGetters({
             target_belongs_original: 'general/getTargetBelongs',
         }),
         selectStudents: {
             get: function() {
                 return this.$store.state.general.selectStudents;
             },
             set: function(value) {
                 this.$store.commit('general/setSelectStudent', value);
             }
         },
         simple_items: {
             get: function() {
                 return this.$store.state.general.simpleTargetSelections;
             },
             set: function(value) {
                 //this.$store.commit('general/setSelectStudent', value);
             },
         },
     },
     data: function() {
         return {
             initial_simple_items: [
                 {id: 'undergraduate_regular', value: true},
                 {id: 'undergraduate_other',   value: true},
                 {id: 'graduate_regular',      value: true},
                 {id: 'graduate_other',        value: true},
             ],
             status_label_map:{
                 regular: '正規',
                 other: '非正規',
             },
             belongs: {
                 target_grades: [],
                 target_status: [],
                 belongsData: [], //画面表示時、belongsテーブルから全データを取得し格納。
                 level_1_data: [],
                 level_2_data: [], //belongsData内の階層コード2(学部・研究科)において一意性のあるデータのみ抽出し格納。
                 level_4_data: [], //選択された階層コード2に該当するデータの内、階層コード4(学科・先行)において一意性のあるデータのみ抽出し格納。
                 level_5_data: [], //選択された階層コード2および4に該当するデータのみ抽出し格納。
                 degree_id: '',
                 root_id: "", //現在選択されている階層コード2(学部・研究科のコード)
                 child_id: "", //現在選択されている階層コード4(学科・先行のコード)
                 gchild_id: "", //現在選択されている階層コード5(コース・分野のコード)
                 degree_name: '',
                 root_name: '',
                 child_name: '',
                 gchild_name: '',
                 selectedDegree: false,
                 selectedFaculty: true,
                 selectedDepartment: true,
                 selectedCourse: true,
                 selected_target_grades: false,
                 selected_target_status: false,
                 addTargetBelongsButton: true,
             },
             isLoading: {
                 lv1: true,
                 lv2: false,
                 lv4: false,
                 lv5: false,
             }
         }
     },

     mounted: function() {
         this.initializeBelongs();
     },
     methods: {
         makeBelongTargetName: function(inf){
             const lv1name = inf.lv1.name == null ? '' : inf.lv1.name;
             const lv2name = inf.lv2.name == null ? '' : inf.lv2.name;
             const lv4name = inf.lv4.name == null ? '' : inf.lv4.name;
             const lv5name = inf.lv5.name == null ? '' : inf.lv5.name;
             return `${lv1name} ${lv2name} ${lv4name} ${lv5name}`;
         },
         makeBelongStatusName: function(inf){
             const statusname = inf.status.map(v => this.status_label_map[v]).sort();
             if (statusname.length <= 0) return '';
             const status_str = statusname.join('・');
             return `(${status_str})`;
         },
         getTargetStudentInfo: function() {
             return {
                 selectStudents: this.$store.state.general.selectStudents,
                 simpleTargetSelections: this.$store.state.general.simpleTargetSelections,
                 target_belongs_original: this.$store.state.general.targetBelongs,
             };
         },
         initializeBelongs: async function() {
             this.$store.dispatch('general/setSelectStudents',{
                 selectStudents: 'selectDetail'});
             this.$store.dispatch('general/setSimpleSelections',this.initial_simple_items);
             const belong_list = await this.get_belong_list({target:1});
             this.isLoading.lv1 = false;
             this.belongs.level_1_data = belong_list;
         },
         onSelectedGetDegreeId: async function(event) {
             const degreeValue = event.target.value;
             const data = Object.values(this.convert_observer(this.belongs.level_1_data));
             this.belongs.degree_id = degreeValue;
             this.belongs.degree_name = degreeValue != '' ? data.filter(function(e) {
                 return e.code == degreeValue; })[0].name : '';
             this.init_root();
             this.init_child();
             this.init_gchild();
             if (this.belongs.degree_id != '') {
                 this.isLoading.lv2 = true;
                 const belong_list = await this.get_belong_list({
                     target:2,
                     lv1:this.belongs.degree_id,
                 });
                 this.belongs.selectedFaculty = false;
                 this.belongs.level_2_data = belong_list;
                 this.isLoading.lv2 = false;
             }
         },
         onSelectedGetRootId: async function(event) {
             const rootValue = event.target.value;
             const data = Object.values(this.convert_observer(this.belongs.level_2_data));
             this.belongs.root_id = rootValue;
             this.belongs.root_name = rootValue != '' ? data.filter(function(e) {
                 return e.code == rootValue; })[0].name : '';
             this.init_child();
             this.init_gchild();
             if (this.belongs.root_id != '') {
                 this.isLoading.lv4 = true;
                 const belong_list = await this.get_belong_list({
                     target:4,
                     lv1:this.belongs.degree_id,
                     lv2:this.belongs.root_id,
                 });
                 this.belongs.selectedDepartment = false;
                 this.belongs.level_4_data = belong_list;
                 this.isLoading.lv4 = false;
             }
         },
         onSelectedGetChildrenId: async function(event) {
             const childValue = event.target.value;
             const data = Object.values(this.convert_observer(this.belongs.level_4_data));
             this.belongs.child_id = childValue;
             this.belongs.child_name = childValue != '' ? data.filter(function(e){
                 return e.code == childValue;})[0].name : '';
             this.init_gchild();
             if (childValue != '') {
                 this.isLoading.lv5 = true;
                 const belong_list = await this.get_belong_list({
                     target:5,
                     lv1:this.belongs.degree_id,
                     lv2:this.belongs.root_id,
                     lv4:this.belongs.child_id,
                 });
                 this.belongs.selectedCourse = false;
                 this.belongs.level_5_data = belong_list;
                 this.isLoading.lv5 = false;
             }
         },
         onSelectedGetGChildrenId: async function(event) {
             const gChildValue = event.target.value;
             const data = Object.values(this.convert_observer(this.belongs.level_5_data));
             this.belongs.gchild_id = gChildValue;
             this.belongs.gchild_name = gChildValue != '' ? data.filter(function(e){
                 return e.code == gChildValue;})[0].name : '';
         },
         //閲覧権限の対象所属追加
         addTargetBelongs() {
             if(this.belongs.target_grades.length === 0) return;
             if (this.belongs.target_status.length === 0) return;

             const target_belongs = this.target_belongs_original;
             const info = {
                 lv1: {code: this.belongs.degree_id,
                       name: this.belongs.degree_name},
                 lv2: {code: this.belongs.root_id,
                       name: this.belongs.root_name},
                 lv4: {code: this.belongs.child_id,
                       name: this.belongs.child_name},
                 lv5: {code: this.belongs.gchild_id,
                       name: this.belongs.gchild_name},
                 grades: this.belongs.target_grades.sort(),
                 status: this.belongs.target_status.sort(),
             };

             //対象所属が既に追加されていないか確認。ヒットした場合は登録されていない年次のみ追加。
             const filtered = target_belongs.filter(function(e){
                 return this.is_already_added_belong(e,info);
             }.bind(this));
             if (filtered.length <= 0) {
                 target_belongs.push(info);
                 this.$store.dispatch('general/setTargetBelongs',{targetBelongs: target_belongs});
                 return;
             }

             //const target_info = filtered[0];

             //const target_grades = target_info.grades;
             //const new_grades = info.grades;
             //const merged_grades = target_grades.concat(new_grades);
             //const uniq_grades = [...(new Set(merged_grades))].sort();
             //target_grades.splice(0);
             //uniq_grades.forEach(function(g){
             //    target_grades.push(g);
             //});

             //const target_status = target_info.status;
             //const new_status = info.status;
             //const merged_status = target_status.concat(new_status);
             //const uniq_status = [...(new Set(merged_status))].sort();
             //target_status.splice(0);
             //uniq_status.forEach(function(s){
             //    target_status.push(s);
             //});

             //this.$store.dispatch('general/setTargetBelongs',{targetBelongs: target_belongs});
         },
         //追加済みの閲覧権限の削除
         deleteTargetBelong(index) {
             this.$store.dispatch('general/deleteBelongByIndex',index);
         },
         init_root: function() {
             this.belongs.root_id = '';
             this.belongs.root_name = '';
             this.belongs.level_2_data = [];
             this.belongs.selectedFaculty = true;
         },
         init_child: function() {
             this.belongs.child_id = '';
             this.belongs.child_name = '';
             this.belongs.level_4_data = [];
             this.belongs.selectedDepartment = true;
         },
         init_gchild: function() {
             this.belongs.gchild_id = '';
             this.belongs.gchild_name = '';
             this.belongs.level_5_data = [];
             this.belongs.selectedCourse = true;
         },
         isDisableAddButton: function() {
             if (this.belongs.target_grades.length <= 0) return true;
             if (this.belongs.target_status.length <= 0) return true;
             return false;
         },
     },
     watch: {
         //閲覧権限を全学生にチェックを入れた場合のメソッド
         selectStudents: function() {
             if(this.selectStudents === "allStudents"){
                 this.belongs.degree_id = "";
                 this.belongs.root_id = "";
                 this.belongs.child_id = '';
                 this.belongs.gchild_id = '';
                 this.belongs.root_name = '';
                 this.belongs.child_name = '';
                 this.belongs.gchild_name = '';
                 this.belongs.selectedDegree = true;
                 this.belongs.selectedFaculty = true;
                 this.belongs.selectedDepartment = true,
                 this.belongs.selectedCourse = true,
                 this.belongs.selected_target_grades = true;
                 this.belongs.selected_target_status = true;
                 this.belongs.target_grades = [];
                 this.belongs.target_status = [];
                 this.belongs.addTargetBelongsButton = true;
             }
             if(this.selectStudents === "selectDetail"){
                 this.belongs.selectedDegree = false;
                 this.belongs.selected_target_grades = false;
                 this.belongs.selected_target_status = false;
             }
         },
         'belongs.target_grades': function(){
             this.belongs.addTargetBelongsButton = this.isDisableAddButton();
         },
         'belongs.target_status': function(){
             this.belongs.addTargetBelongsButton = this.isDisableAddButton();
         },
     }
 }
</script>
