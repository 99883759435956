export default {
    methods: {
        _ct: function(key) {
            return this._t_je(key);
        },
        _t_je: function(key) {
            const ts = [this.$t(key,'ja'),this.$t(key,'en')];
            const valid_ts = ts.filter(t => t != null && t != '' && t != key);
            const tstr = valid_ts.join(' / ');
            return tstr;
        },
    }
};
