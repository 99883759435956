<template>
    <div id="filters" class="card-wrapper gray-border">
        <h3 v-if="!isGuestUser()" class="card-title">フィルタ / Filter</h3>
        <h3 v-else class="card-title"></h3>
        <div class="card-body">
            <select v-if="!isGuestUser()" id="filter" class="form-control" v-model="filter" @change="selectedFilter">
                <option value="NONE">フィルタなし(ALL)</option>
                <option value="UNREAD">未読(New)</option>
                <option value="READ">既読(Read)</option>
                <option value="FAV">お気に入り(Favorite)</option>
                <option v-if="isContributor()" value="DRAFT">下書き(Draft)</option>
            </select>
            <select id="unit" class="form-control" v-model="unit" @change="selectedUnit">
                <option value="20">20件</option>
                <option value="50">50件</option>
                <option value="100">100件</option>
            </select>
        </div>
    </div>
</template>
<script>
 export default {
     props: {
         auth_json_str:{type:String,required:true},
     },
     data: function() {
         return {
             auth: null,
             unit: 20,
             filter: "NONE"
         }
     },
     mounted: async function() {
         this.auth = JSON.parse(this.auth_json_str);
     },
     methods: {
         selectedUnit: function(val) {
             this.$emit('selectedUnit', parseInt(this.unit));
         },
         selectedFilter: function(val) {
             this.$emit('selectedFilter', this.filter);
         },
         isGuestUser: function() {
             if (this.auth == null) return true;
             return this.auth.is_guest;
         },
         isContributor: function() {
             if (this.auth == null) return false;
             return this.auth.is_contributor;
         },
     }
 }
</script>
