var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "browse-post" } }, [
    _c("h1", [_vm._v("掲示情報 / Information")]),
    _vm._v(" "),
    !_vm.isGuestUser()
      ? _c("div", { staticClass: "unread" }, [
          _c(
            "a",
            { attrs: { href: _vm.makeUnreadPath(_vm.postData.id) } },
            [
              _c("fa-icon", { attrs: { icon: "envelope" } }),
              _vm._v("\n            未読に戻す/Put back on unread\n        "),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-wrapper green-border", attrs: { id: "post" } },
      [
        _vm.isEmptyPostData()
          ? _c("h2", { staticClass: "title" }, [
              _c("div", { staticClass: "title-left" }, [
                _vm._v("\n                Loading…\n            "),
              ]),
            ])
          : _c("h2", { staticClass: "title" }, [
              _c("div", { staticClass: "title-left" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.postData.title) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              !_vm.isGuestUser()
                ? _c("div", { staticClass: "title-right" }, [
                    _c(
                      "div",
                      {
                        staticClass: "fav",
                        class: { "non-fav": !_vm.markedFav },
                        on: {
                          click: function ($event) {
                            return _vm.toggleFav(_vm.markedFav, $event)
                          },
                        },
                      },
                      [_c("fa-icon", { attrs: { icon: "star" } })],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _vm.isEmptyPostData() || _vm.postData.body == ""
            ? _c("div", { staticClass: "body dummy-height" })
            : _c("div", {
                staticClass: "body",
                domProps: {
                  innerHTML: _vm._s(_vm.formatMessageBody(_vm.postData.body)),
                },
              }),
          _vm._v(" "),
          _c("div", { staticClass: "information" }, [
            _c("div", { staticClass: "info" }, [
              _c("div", { staticClass: "english-notation" }, [
                _vm._v("Contributor"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "post-item" }, [
                _c("label", [_vm._v("掲載者")]),
                _vm._v(" "),
                !_vm.isEmptyPostData()
                  ? _c("div", { staticClass: "value" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.postData.contributor_name) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info" }, [
              _c("div", { staticClass: "english-notation" }, [
                _vm._v("Contact information"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "post-item" }, [
                _c("label", [_vm._v("連絡先")]),
                _vm._v(" "),
                !_vm.isEmptyPostData()
                  ? _c("div", { staticClass: "value" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.postData.contact_mail) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info" }, [
              _c("div", { staticClass: "english-notation" }, [
                _vm._v("Posting period"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "post-item" }, [
                _c("label", [_vm._v("掲載期間")]),
                _vm._v(" "),
                !_vm.isEmptyPostData() &&
                _vm.postData.publish_from !== null &&
                _vm.postData.publish_from !== ""
                  ? _c("div", { staticClass: "value" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.disp_datetime_format(_vm.postData.publish_from)
                          ) +
                          "\n                             ～ \n                            " +
                          _vm._s(
                            _vm.disp_datetime_format(_vm.postData.publish_to)
                          ) +
                          "\n                        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info" }, [
              _c("div", { staticClass: "english-notation" }, [
                _vm._v("Target student"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "post-item" },
                [
                  _c("label", [_vm._v("対象学生所属")]),
                  _vm._v(" "),
                  !_vm.isEmptyPostData()
                    ? [
                        _vm.postData.selection.selectStudents == "allStudents"
                          ? _c("div", { staticClass: "value" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.makeBelongSimpleTargetName()) +
                                  "\n                            "
                              ),
                            ])
                          : _c(
                              "div",
                              { staticClass: "value" },
                              _vm._l(
                                _vm.postData.selection.target_belongs_original,
                                function (belong) {
                                  return _c(
                                    "div",
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.makeBelongName(belong)) +
                                          "\n                                    "
                                      ),
                                      _vm._l(belong.grades, function (grade) {
                                        return _c("span", [
                                          _vm._v(_vm._s(" " + grade) + "年"),
                                        ])
                                      }),
                                    ],
                                    2
                                  )
                                }
                              ),
                              0
                            ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info attachments" }, [
              _c("div", { staticClass: "english-notation" }, [
                _vm._v("Attachement\n                    "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "post-item" }, [
                _c("label", [
                  _vm._v(
                    "\n                            添付ファイル\n                        "
                  ),
                ]),
                _vm._v(" "),
                _vm.postData.attachments == null ||
                _vm.postData.attachments.length <= 0
                  ? _c("div", { staticClass: "value" }, [_vm._v("添付なし")])
                  : _c(
                      "div",
                      { staticClass: "value" },
                      _vm._l(_vm.postData.attachments, function (attachment) {
                        return _c("div", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.makeDownloadPath(
                                  _vm.postData.id,
                                  attachment.id
                                ),
                              },
                            },
                            [
                              _c("fa-icon", { attrs: { icon: "paperclip" } }),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(attachment.name) +
                                  "　(" +
                                  _vm._s(
                                    _vm.byte_to_kilobyte(attachment.size) + "KB"
                                  ) +
                                  ")\n                                "
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "btn_area_right" }, [
              _vm.postData.isEditable
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { href: _vm.makeEditPath(_vm.postData.id) },
                    },
                    [_vm._v("編集 / Edit")]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "btn_area detail-close" }, [
      _c(
        "a",
        { staticClass: "btn btn-close", attrs: { onclick: "window.close();" } },
        [_vm._v(" 閉じる / Close")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }