<template>
    <div class="setting-genre setting-genre-index">
        <h1>設定 - ジャンル</h1> 
        <div class="card-wrapper gray-border">
            <h3 class="card-title">
                ジャンル一覧
            </h3>
            <div class="card-body">
                <table class="table">
                    <tr>
                        <th>表示順</th>
                        <th>ジャンル名</th>
                        <th></th>
                    </tr>
                    <tr v-for="genre in this.genres">
                        <td>{{ genre.no }}</td>
                        <td>{{ genre.name }}</td>
                        <td>
                            <a v-bind:href="'/setting/genre/edit/' + genre.id">編集</a>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="button_area">
            <a class="btn btn-secondary" href="/setting">戻る</a>
            <a class="btn btn-primary"   href="/setting/genre/create">追加</a>
        </div>
    </div>
</template>
<script>
    export default {
        data: function() { 
            return {
                genres: []
            }
        },
        mounted: function() {
            this.getList();
        },
        methods: {
            getList: function() {
                var self = this;
                axios.get("/api/v1/get/genres",{
                }).then(response => {
                    self.genres = response.data.data;
                }).catch(error => { 
                    console.log(error);
                })
            }
        }
    }
</script>
