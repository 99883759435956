<template>
<div class="card-body">
  <select class="form-control" v-model="selectedGenre" @change="onChange" >
    <option v-if="includeall" value="">{{_ct('item.option.genre.all') }}</option>
    <option v-for="genre in this.genres" v-bind:value="genre.id">
      {{ genre.name }}
    </option>
  </select>
</div>
</template>
<script>
    import { mapGetters } from 'vuex';
    export default {
        props: {
            includeall:{type:Boolean,default:false},
        },
        computed: {
            selectedGenre: {
                get: function() {
                    return this.$store.state.general.genre_id;
                },
                set: function(val) {
                    this.$store.dispatch('general/setPostGenreId',val);
                },
            },
        },
        data: function() { 
            return {
                initialdata: [{id: "", name: "Loading…"}],
                genres: [],
                isUpdating: true,
                reservedId: null,
            }
        },
        mounted: function() {
            this.updateList();
        },
        methods: {
            initializeList: function() {
                this.genres = this.initialdata;
            },
            getDefaultItemId: function() {
                if (this.genres.length == null || this.genres.length == 0)
                    return '';
                return this.includeall ? '' : this.genres[0].id;
            },
            updateList: async function() {
                this.isUpdating = true;

                this.initializeList();
                const genres = await this.get_all_genre_list();
                if (genres == null) {
                    this.genres = [];
                    return;
                }
                this.saveLocalStrage("genres", genres);
                this.genres = genres;

                const id = this.reservedId == null ? this.getDefaultItemId() : this.reservedId;
                this.$store.dispatch('general/setPostGenreId',id);
                this.reservedId = null;

                this.isUpdating = false;

                // 親要素に伝搬させる
                this.$emit('selectedGenre', this.selectedGenre);
            },
            onChange: function(evt) {
                const val = evt.target.value;
                this.$store.dispatch('general/setPostGenreId',val);
                this.$emit('selectedGenre', this.selectedGenre);
            },
            setGenreId: function(id) {
                if (this.isUpdating) {
                    this.reservedId = id;
                } else {
                    this.reservedId = null;
                    this.$store.dispatch('general/setPostGenreId',id);
                }
            },
            getSelected: function() {
                return this.$store.state.general.genre_id;
            },
        },
    }
</script>
