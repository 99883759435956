var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "setting-genre setting-genre-create" }, [
    _c("h1", [_vm._v("設定 - ジャンル - 登録")]),
    _vm._v(" "),
    _vm.errors.length > 0
      ? _c("div", { staticClass: "alert alert-danger" }, [
          _c(
            "ul",
            { staticClass: "error_ul" },
            _vm._l(_vm.errors, function (error) {
              return _c("li", [_vm._v(" " + _vm._s(error) + " ")])
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "card-wrapper gray-border" }, [
      _c("h3", { staticClass: "card-title" }, [
        _vm._v("\n            設定値一覧\n        "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "item" }, [
          _c("label", [_vm._v("表示順")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.genre.no,
                  expression: "genre.no",
                },
              ],
              attrs: { type: "number" },
              domProps: { value: _vm.genre.no },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.genre, "no", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("label", [_vm._v("ジャンル名")]),
          _vm._v(" "),
          _c("div", { staticClass: "value" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.genre.name,
                  expression: "genre.name",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.genre.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.genre, "name", $event.target.value)
                },
              },
            }),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "button_area" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-secondary return",
          attrs: { href: "/setting/genre" },
        },
        [_vm._v("戻る")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-primary",
          on: {
            click: function ($event) {
              return _vm.createGenre()
            },
          },
        },
        [_vm._v("登録")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }