<template>
    <div id="genres" class="card-wrapper gray-border">
        <h3 class="card-title">{{ _ct('title.genre_dropdown') }}</h3>
        <div class="card-body">
            <select class="form-control" v-model="selectedGenre" @change="onChange()" >
                <option v-for="genre in this.genres" v-bind:value="genre.id">
                    {{ genre.name }}
                </option>
            </select>
        </div>
    </div>
</template>
<script>
    export default {
        data: function() { 
            return {
                initialdata: [{id: "", name: "Loading…"}],
                genres: [],
                selectedGenre: ""
            }
        },
        mounted: function() {
            this.updateList();
        },
        methods: {
            initializeList: function() {
                this.genres = this.initialdata;
            },
            updateList: async function() {
                this.initializeList();
                const genres = await this.get_all_genre_list();
                if (genres == null) {
                    this.genres = [];
                    return;
                }
                this.saveLocalStrage("genres", genres);
                this.genres = genres;
                // 1件目を自動選択
                this.selectedGenre = genres[0].id;
                // 親要素に伝搬させる
                this.$emit('selectedGenre', this.selectedGenre);
            },
            onChange: function(val) {  
                this.$emit('selectedGenre', this.selectedGenre);
            },
        }
    }
</script>
