const state = {
    genre_id: null,
    attachments: [],
    uploadingFile: null,
    selectStudents: null,
    simpleTargetSelections: [],
    targetBelongs: [],
    flg_mail: false,
    mail_send_datetime: null,
};

const mutations = {
    setGenreId: (state,id) => {
        state.genre_id = id;
    },
    setAttachmentList: (state,attachments) => {
        state.attachments = attachments.length == null ? [] : attachments;
    },
    setUploadingFile:(state,filename) => {
        state.uploadingFile = filename;
    },
    setSelectStudent: (state,value) => {
        state.selectStudents = value;
    },
    setSimpleTargetSelection: (state,inf) => {
        state.simpleTargetSelections = inf.length == null ? [] : inf;
    },
    setTargetBelong: (state,inf) => {
        state.targetBelongs = inf.length == null ? [] : inf;
    },
    deleteTargetBelongByIndex: (state,index) => {
        state.targetBelongs.splice(index,1);
    },
    setFlgMail: (state,b) => {
        state.flg_mail = b;
    },
    setMailSendDatetime: (state,datetime) => {
        state.mail_send_datetime = datetime;
    },
};
const actions = {
    setPostGenreId: ({commit,state}, id) => {
        commit('setGenreId', id);
    },
    setAttachments: ({commit,state}, args) => {
        commit('setAttachmentList', args.attachments);
    },
    setUploading: ({commit,state}, args) => {
        commit('setUploadingFile', args.filename);
    },
    unsetUploading(ctx) {
        ctx.commit('setUploadingFile',null);
    },
    setSelectStudents: ({commit,state}, args) => {
        commit('setSelectStudent',args.selectStudents);
    },
    setSimpleSelections: ({commit,state}, inf) => {
        commit('setSimpleTargetSelection',inf);
    },
    setTargetBelongs: ({commit,state}, args) => {
        commit('setTargetBelong',args.targetBelongs);
    },
    deleteBelongByIndex: ({commit,state}, index) => {
        commit('deleteTargetBelongByIndex',index);
    },
    setSendMailFlag: ({commit,state}, b) => {
        commit('setFlgMail',b);
    },
    setDatetimeForSendingMail: ({commit,state}, datetime) => {
        commit('setMailSendDatetime',datetime);
    },
};
const getters = {
    getPostGenreId(state) {
        return state.genre_id;
    },
    getAttachments(state) {
        return state.attachments;
    },
    isUploading(state) {
        return state.uploadingFile != null;
    },
    getUploadingFilename(state) {
        return state.uploadingFile;
    },
    getSelectStudents(state) {
        return state.selectStudents;
    },
    getSimpleSelections(state) {
        return state.simpleTargetSelections;
    },
    getTargetBelongs(state) {
        return state.targetBelongs;
    },
    getSendMailFlag(state) {
        return state.flg_mail;
    },
    getSendMailDatetime(state) {
        return state.mail_send_datetime;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
