var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "create-post" } }, [
    _c("h1", [_vm._v("全学掲示板：新規投稿 / New Posting to Bulletin Board")]),
    _vm._v(" "),
    _vm.errors.length > 0
      ? _c("div", { staticClass: "alert alert-danger" }, [
          _c(
            "ul",
            { staticClass: "error_ul" },
            _vm._l(_vm.errors, function (error) {
              return _c("li", [_vm._v(" " + _vm._s(error) + " ")])
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.createPost.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "belong-item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: { titlekey: "title.target_student", required: true },
            }),
            _vm._v(" "),
            _c("target-student", { ref: "targetStudent" }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: { titlekey: "title.genre_dropdown", required: true },
            }),
            _vm._v(" "),
            _c("genre-select", { ref: "genre" }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: { titlekey: "title.title", required: true },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.title,
                    expression: "title",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", maxlength: "100" },
                domProps: { value: _vm.title },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.title = $event.target.value
                  },
                },
              }),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: { titlekey: "title.body", required: true },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.body,
                    expression: "body",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  rows: "8",
                  placeholder: "テキストでご入力ください / Enter as text",
                  autocomplete: "URL",
                  maxlength: "10000",
                },
                domProps: { value: _vm.body },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.body = $event.target.value
                  },
                },
              }),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: { titlekey: "title.attachment", required: false },
            }),
            _vm._v(" "),
            _c("upload-file", {
              ref: "uploadfile",
              attrs: { id: _vm.temp_id, update: false },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: { titlekey: "title.period", required: true },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "card-body center" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.publish_from,
                    expression: "publish_from",
                  },
                ],
                staticClass: "form-control datetime",
                attrs: {
                  type: "datetime-local",
                  min: "2000-01-01T00:00",
                  max: "2037-12-31T23:59",
                },
                domProps: { value: _vm.publish_from },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.publish_from = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "unit" }, [_vm._v("～")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.publish_to_date,
                    expression: "publish_to_date",
                  },
                ],
                staticClass: "form-control datetime",
                attrs: { type: "date", min: "2000-01-01", max: "2037-12-31" },
                domProps: { value: _vm.publish_to_date },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.publish_to_date = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.publish_to_hour,
                      expression: "publish_to_hour",
                    },
                  ],
                  staticClass: "form-control time",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.publish_to_hour = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.optionTimes, function (time) {
                  return _c("option", { key: time.id }, [_vm._v(_vm._s(time))])
                }),
                0
              ),
              _vm._v(" "),
              _c("span", { staticClass: "unit" }, [_vm._v("：")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.publish_to_minutes,
                      expression: "publish_to_minutes",
                    },
                  ],
                  staticClass: "form-control time",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.publish_to_minutes = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.optionMinutes, function (time) {
                  return _c("option", { key: time.id }, [_vm._v(_vm._s(time))])
                }),
                0
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: { titlekey: "title.contributor", required: true },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.created_user_name,
                    expression: "created_user_name",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", maxlength: "512" },
                domProps: { value: _vm.created_user_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.created_user_name = $event.target.value
                  },
                },
              }),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: { titlekey: "title.contact", required: false },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.contact_mail,
                    expression: "contact_mail",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", maxlength: "512" },
                domProps: { value: _vm.contact_mail },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.contact_mail = $event.target.value
                  },
                },
              }),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: {
                titlekey: "title.mail",
                required: false,
                forkey: "flg_mail",
              },
            }),
            _vm._v(" "),
            _c("send-mail", { ref: "sendmail", attrs: { id: "flg_mail" } }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: {
                titlekey: "title.editor",
                required: false,
                forkey: "editor",
                descr:
                  "(ex. user1@office.gs.chiba-u.jp,user2@office.gs.chiba-u.jp)",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.editor,
                    expression: "editor",
                  },
                ],
                staticClass: "form-control",
                attrs: { id: "editor", type: "text" },
                domProps: { value: _vm.editor },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.editor = $event.target.value
                  },
                },
              }),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item card-wrapper gray-border" },
          [
            _c("form-title", {
              attrs: {
                titlekey: "title.draft",
                required: false,
                forkey: "isDraft",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.is_draft,
                    expression: "is_draft",
                  },
                ],
                staticClass: "form-control is-draft",
                attrs: { id: "isDraft", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.is_draft)
                    ? _vm._i(_vm.is_draft, null) > -1
                    : _vm.is_draft,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.is_draft,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.is_draft = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.is_draft = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.is_draft = $$c
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "is-draft-attention" }, [
                _vm._v(
                  "(下書き時はメール送信されません / Email will not be sent when drafting)"
                ),
              ]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "operation-area" }, [
          _c(
            "button",
            {
              staticClass: "submit btn btn-primary",
              attrs: { disabled: _vm.submit, type: "submit" },
            },
            [_vm._v("登録 / Register")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }