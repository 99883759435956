var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.postsCount > 0
      ? _c(
          "div",
          { staticClass: "pager-links" },
          [
            _c(
              "div",
              {
                staticClass: "pager-link left",
                class: { disabled: _vm.isPrevDisabled() },
                attrs: { "data-type": "first", disabled: _vm.isPrevDisabled() },
                on: { click: _vm.tapPager },
              },
              [_c("fa-icon", { attrs: { icon: "angle-double-left" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "pager-link",
                class: { disabled: _vm.isPrevDisabled() },
                attrs: { "data-type": "prev", disabled: _vm.isPrevDisabled() },
                on: { click: _vm.tapPager },
              },
              [_c("fa-icon", { attrs: { icon: "angle-left" } })],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.pageArray, function (page) {
              return _c(
                "div",
                {
                  staticClass: "pager-link index",
                  class: { current: _vm.isCurrent(page) },
                  on: { click: _vm.tapPager },
                },
                [_vm._v(_vm._s(page))]
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "pager-link",
                class: { disabled: _vm.isNextDisabled() },
                attrs: { "data-type": "next", disabled: _vm.isNextDisabled() },
                on: { click: _vm.tapPager },
              },
              [_c("fa-icon", { attrs: { icon: "angle-right" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "pager-link right",
                class: { disabled: _vm.isNextDisabled() },
                attrs: { "data-type": "last", disabled: _vm.isNextDisabled() },
                on: { click: _vm.tapPager },
              },
              [_c("fa-icon", { attrs: { icon: "angle-double-right" } })],
              1
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }