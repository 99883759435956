var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "setting-genre setting-genre-index" }, [
    _c("h1", [_vm._v("設定 - ジャンル")]),
    _vm._v(" "),
    _c("div", { staticClass: "card-wrapper gray-border" }, [
      _c("h3", { staticClass: "card-title" }, [
        _vm._v("\n            ジャンル一覧\n        "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "table",
          { staticClass: "table" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(this.genres, function (genre) {
              return _c("tr", [
                _c("td", [_vm._v(_vm._s(genre.no))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(genre.name))]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "a",
                    { attrs: { href: "/setting/genre/edit/" + genre.id } },
                    [_vm._v("編集")]
                  ),
                ]),
              ])
            }),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("表示順")]),
      _vm._v(" "),
      _c("th", [_vm._v("ジャンル名")]),
      _vm._v(" "),
      _c("th"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "button_area" }, [
      _c(
        "a",
        { staticClass: "btn btn-secondary", attrs: { href: "/setting" } },
        [_vm._v("戻る")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-primary",
          attrs: { href: "/setting/genre/create" },
        },
        [_vm._v("追加")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }