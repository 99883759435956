<template>
    <div class="setting-index">
        <h1>設定</h1> 
        <ul>
            <li>
                <a href="/setting/general">一般設定</a>
            </li>
            <li>
                <a href="/setting/genre">ジャンル編集</a>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
    }
</script>
