module.exports = function() {
    return {
        title: {
            post_list: 'Posts list',
            genre_dropdown: 'Genre',
            search_criteria: 'Search criteria',
            search_words: 'Search words',
            article_list: '',
            target_student: 'Target student',
            title: 'Title',
            body: 'Text',
            attachment: 'Attached File',
            period: 'Posting period',
            contributor: 'Contributor(Affiliation・Name etc.)',
            contact: 'Contact information',
            mail: 'Whether to deliver email',
            last_noticed_datetime: '',
            editor: 'Co-editing User',
            draft: 'Save as a draft',
            simple_setting: '',
            detail_setting: 'More Detailed Specification',
        },
        item: {
            option: {
                genre: {
                    all: '',
                },
            },
            inputlabel: {
                target_select: {
                    undergraduate_regular: '',
                    undergraduate_other: '',
                    graduate_regular: '',
                    graduate_other: '',
                    degree: 'Degree',
                    school: 'School',
                    division: 'Department・Division',
                    course: 'Course・Field',
                    grade: 'Grades',
                    status: 'Student Categories',
                },
            },
        },
        placeholder: {
            search_words: 'Search words',
        },
        button: {
            exec_search: 'Search',
        },
        notice: {
            required: 'required',
        },
        message: {
            info: {
                loading: '',
                no_post: '',
                mark_favorite: 'Added to favorites',
                remove_favorite: 'Removed from favorites',
                wait_uploading: '',
            },
            error: {
                e001: '',
                e002: '',
                e004: '',
                e005: '',
                e006: '',
                e008: '',
                e010: '',
                e012: '',
                e013: '',
                upload_error: '',
            },
        },
    };
};
