var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v(_vm._s(_vm._ct("title.post_list")))]),
      _vm._v(" "),
      _c("general-search", {
        attrs: { auth_json_str: _vm.auth_json_str },
        on: {
          inputWord: _vm.inputWord,
          execSearch: _vm.execSearch,
          onChangeShoMyBelongs: _vm.onChangeShoMyBelongs,
        },
      }),
      _vm._v(" "),
      _c("general-list", {
        ref: "generalList",
        attrs: {
          searchWord: _vm.search_word,
          showMyBelongs: _vm.showMyBelongs,
          auth_json_str: _vm.auth_json_str,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }