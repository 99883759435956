<template>
    <div v-if="offset != null && getMax() > 0">
        <div class="pager-label">{{ getFrom() }}-{{ getTo() }} out of {{ getMax() }}</div>
    </div>
</template>
<script>
    export default {
        props: {
            currentPage: Number,
            unit: Number,
            postsCount: Number
        },
        data: function() { 
            return {
                offset: null,
                count: null,
                max: null
            }
        },
        watch: {
            // 親からページ情報を渡される際の処理
            currentPage: function() {
                this.update();
            }, 
            unit: function() {
                this.update();
            },
            postsCount: function() {
                this.update();
            }
        },
        methods: {
            update: function() {
                this.offset = (this.currentPage - 1) * this.unit + 1;
                this.count  = this.unit;
                this.max    = this.postsCount;
            },
            getMax: function() {
                return this.max;
            },
            getFrom: function() {
                return this.offset;
            },
            getTo: function() {
                var val = this.offset + this.count - 1;
                if( val > this.max ) {
                    val = this.max;
                }
                return val;
            }
        }
    }
</script>

<style scoped>
    .pager-label {
        margin: 5px 0px;
        padding: 5px 0px 3px 5px;
    }
</style>
