var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card-wrapper", attrs: { id: "result" } }, [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("pager-filter", {
            attrs: { auth_json_str: _vm.auth_json_str },
            on: {
              selectedUnit: _vm.selectedUnit,
              selectedFilter: _vm.selectedFilter,
            },
          }),
          _vm._v(" "),
          _c("pager-label", {
            attrs: {
              currentPage: _vm.currentPage,
              unit: _vm.unit,
              postsCount: _vm.postsCount,
            },
          }),
          _vm._v(" "),
          _vm.isLoading === true
            ? _c("div", { staticClass: "loading-content" }, [_vm._m(1)])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              { staticClass: "row", attrs: { id: "postList" } },
              [
                this.viewPosts.length === 0 && !_vm.isLoading
                  ? _c("div", { staticClass: "no_post" }, [
                      _vm._v("投稿がありません"),
                    ])
                  : _vm._l(this.viewPosts, function (post) {
                      return _c(
                        "div",
                        {
                          staticClass: "col-12 card_block",
                          class: { "read-card": !post.isUnread },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "post",
                              class: { "col-12": !post.isUnread },
                              attrs: {
                                target: "blank",
                                href: _vm.makeDetailPath(post.id),
                              },
                            },
                            [
                              _c("div", { staticClass: "title" }, [
                                _c("div", { staticClass: "title-left" }, [
                                  post.is_draft == true
                                    ? _c("span", [
                                        _c("i", { staticClass: "fas fa-edit" }),
                                        _vm._v("(下書き/Draft) "),
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(post.title) +
                                      "\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                !_vm.isGuestUser()
                                  ? _c("div", { staticClass: "title-right" }, [
                                      _vm.isNewPost(post)
                                        ? _c("div", { staticClass: "unread" }, [
                                            _vm._v("NEW"),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "fav",
                                          class: {
                                            "non-fav": !post.isFavorite,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleFav(
                                                post.id,
                                                post.isFavorite,
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("fa-icon", {
                                            attrs: { icon: "star" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "information" }, [
                            _c(
                              "a",
                              {
                                staticClass: "post",
                                class: { "col-12": !post.isUnread },
                                attrs: {
                                  target: "blank",
                                  href: _vm.makeDetailPath(post.id),
                                },
                              },
                              [
                                _c("div", { staticClass: "waku" }, [
                                  _c("div", { staticClass: "post-item-area" }, [
                                    _c(
                                      "div",
                                      { staticClass: "english-notation" },
                                      [_vm._v("Contributor")]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "post-item" }, [
                                      _c("label", [_vm._v("掲載者 ")]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "post-value" }, [
                                        _vm._v(_vm._s(post.contributor_name)),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "waku" }, [
                                  _c("div", { staticClass: "post-item-area" }, [
                                    _c(
                                      "div",
                                      { staticClass: "english-notation" },
                                      [_vm._v("Date of update")]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "post-item" }, [
                                      _c("label", [_vm._v("更新日時")]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.disp_date_format(
                                              post.updated_at
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "edit-btn-area" }, [
                              post.isEditable
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "btn btn-primary",
                                      attrs: {
                                        target: "blank",
                                        href: _vm.makeEditPath(post.id),
                                      },
                                    },
                                    [_vm._v("編集 / Edit")]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "read" }),
                        ]
                      )
                    }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("laravel-paginator", {
            attrs: {
              currentPage: _vm.currentPage,
              unit: _vm.unit,
              postsCount: _vm.postsCount,
            },
            on: { setCurrentPage: _vm.setCurrentPage },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "card-title" }, [
      _c("span", [_vm._v("記事一覧 / Article List")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading-message" }, [
      _c("img", { attrs: { src: "/images/ajax-loader.gif" } }),
      _vm._v(" Loading…"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }