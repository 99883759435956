<template>
  <h3 class="card-title" :for="getForKey()">
    {{ title_ja() }}
    <span class="required" v-if="required"> ({{ required_ja() }})</span>
    / {{ title_en() }}
    <span class="required" v-if="required"> ({{ required_en() }})</span>
    <span v-if="descr != ''">{{ descr }}</span>
  </h3>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex';
export default {
    props: {
        titlekey:{type:String,required:true},
        required:{type:Boolean,default:false},
        forkey:{type:String,default:''},
        descr:{type:String,default:''},
    },
    computed: {
    },
    data: function() {
        return {
        }
    },
    mounted: function() {
    },
    methods: {
        title_ja: function() {
            return this.$t(this.titlekey,'ja');
        },
        title_en: function() {
            return this.$t(this.titlekey,'en');
        },
        required_ja: function() {
            return this.$t('notice.required','ja');
        },
        required_en: function() {
            return this.$t('notice.required','en');
        },
        getForKey: function() {
            return this.forkey == '' ? null : this.forkey;
        },
    }
}
</script>
