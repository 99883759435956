var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-body" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedGenre,
            expression: "selectedGenre",
          },
        ],
        staticClass: "form-control",
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selectedGenre = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.onChange,
          ],
        },
      },
      [
        _vm.includeall
          ? _c("option", { attrs: { value: "" } }, [
              _vm._v(_vm._s(_vm._ct("item.option.genre.all"))),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(this.genres, function (genre) {
          return _c("option", { domProps: { value: genre.id } }, [
            _vm._v("\r\n      " + _vm._s(genre.name) + "\r\n    "),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }