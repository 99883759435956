module.exports = function() {
    return {
        title: {
            post_list: '掲示情報一覧',
            genre_dropdown: 'ジャンル',
            search_criteria: '検索条件',
            search_words: '検索ワード（表題・内容・所属・掲載者）',
            article_list: '',
            target_student: '対象学生指定',
            title: '表題',
            body: '本文',
            attachment: '添付ファイル',
            period: '掲示期間',
            contributor: '掲載者(所属・担当者等)',
            contact: '連絡先',
            mail: 'メール配信',
            last_noticed_datetime: '最終メール送信日時',
            editor: '共同編集ユーザ',
            draft: '下書きとして作成',
            simple_setting: '一括指定',
            detail_setting: '詳細指定',
        },
        item: {
            option: {
                genre: {
                    all: '全て',
                },
            },
            inputlabel: {
                target_select: {
                    undergraduate_regular: '学部生(正規)',
                    undergraduate_other: '学部生(非正規)',
                    graduate_regular: '大学院生(正規)',
                    graduate_other: '大学院生(非正規)',
                    degree: '設置区分',
                    school: '学部・研究科',
                    division: '学科・専攻',
                    course: 'コース・分野',
                    grade: '学年',
                    status: '学生区分',
                },
            },
        },
        placeholder: {
            search_words: '検索ワード',
        },
        button: {
            exec_search: '検索',
        },
        notice: {
            required: '必須',
        },
        message: {
            info: {
                loading: '',
                no_post: '',
                mark_favorite: 'お気に入りに追加しました',
                remove_favorite: 'お気に入りから削除しました',
                wait_uploading: 'アップロード処理中です。完了までお待ち下さい。',
            },
            error: {
                e001: 'E001-データの読み込みに失敗しました。画面を再読み込みしてください。',
                e002: 'E002-処理に失敗しました。画面を再読み込みしてください。',
                e004: 'E004-処理に失敗しました。画面を再読み込みしてください。',
                e005: 'E005-処理に失敗しました。画面を再読み込みしてください。',
                e006: 'E006-所属情報の取得に失敗しました。画面を再読み込みしてください。',
                e008: 'E008-取得に失敗しました。画面を再読み込みしてください。',
                e010: 'E010-記事の投稿に失敗しました。',
                e012: 'E012-処理に失敗しました。画面を再読み込みしてください。',
                e013: 'E013-データの読み込みに失敗しました。画面を再読み込みしてください。',
                upload_error: '添付ファイル送信失敗。理由が不明な場合は管理者に連絡してください。',
            },
        },
    };
};
