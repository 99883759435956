<template>
    <div>
        <h1>{{ _ct('title.post_list') }}</h1>
        <general-search
            @inputWord="inputWord"
            @execSearch="execSearch"
            @onChangeShoMyBelongs="onChangeShoMyBelongs"
            :auth_json_str="auth_json_str"
        ></general-search>
        <general-list
            ref="generalList"
            :searchWord="search_word"
            :showMyBelongs="showMyBelongs"
            :auth_json_str="auth_json_str"
        ></general-list>
    </div>
</template>
<script>
 import BBSSearch  from './BBSSearch.vue';
 import BBSList    from './BBSList.vue';
 export default {
     components: {
         BBSSearch,
         BBSList
     },
     props: {
         auth_json_str:{type:String,required:true},
     },
     data: function() {
         return {
             auth: null,
             user: null,
             search_word: "",
             genre: "",
             showMyBelongs: true,
         }
     },
     mounted: function() {
         this.auth = JSON.parse(this.auth_json_str);
     },
     watch: {
     },
     methods: {
         // 子のコンポーネントから呼び出される関数
         inputWord: function(val) {
             this.search_word = val;
         },
         execSearch: function() {
             const genreid = this.$store.state.general.genre_id;
             this.$refs.generalList.getPosts(genreid);
         },
         onChangeShoMyBelongs: function(val) {
             this.showMyBelongs = val;
         },
         selectedGenre: function(genre) {
             this.genre = genre;
             this.$refs.generalList.getPosts(this.genre);
         },
     }
 }
</script>
