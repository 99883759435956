<template>
    <div class="card-body">
        <input class="form-control mail-check" type="checkbox" :id="id" v-model="flg_mail">
        <input class="form-control mail-date" type="date" min="2000-01-01" max="2037-12-31" v-model="formatted_mail_send_date">
        <select class="form-control mail-time" v-model="formatted_mail_send_hour">
            <option v-for="time in optionTimes" :key="time.id">{{ time }}</option>
        </select>
        <span class="unit">：</span>
        <select class="form-control time" v-model="formatted_mail_send_minute">
            <option v-for="time in optionMinutes" :key="time.id">{{ time }}</option>
        </select>
        <p v-if="last_noticed_datetime != null && last_noticed_datetime != ''"
           class="last-noticed-datetime">
          {{_ct('title.last_noticed_datetime')}} : {{last_noticed_datetime}}
        </p>
    </div>
</template>

<script>
 import moment from "moment";
 export default {
     props: {
         id:{type:String,required:true},
         last_noticed_datetime:{type:String,default:null},
     },
     computed: {
         flg_mail: {
             get: function() {
                 return this.$store.state.general.flg_mail;
             },
             set: function(value) {
                 this.$store.dispatch('general/setSendMailFlag',value);
             },
         },
         formatted_mail_send_date: {
             get: function() {
                 let datetime = this.$store.state.general.mail_send_datetime;
                 if (datetime == null) return null;
                 return datetime.format('YYYY-MM-DD');
             },
             set: function(value) {
                 let m = moment(value);
                 let datetime = null;
                 if (m.isValid()) {
                     datetime = this.$store.state.general.mail_send_datetime;
                     if (datetime == null) {
                         datetime = this.getDefaultDatetime();
                     }
                     const new_y = m.get('year');
                     const new_m = m.get('month');
                     const new_d = m.get('date');
                     datetime.set({year: new_y, month: new_m, date: new_d});
                 }
                 this.$store.dispatch('general/setDatetimeForSendingMail',datetime);
             },
         },
         formatted_mail_send_hour: {
             get: function() {
                 let datetime = this.$store.state.general.mail_send_datetime;
                 if (datetime == null) return this.mail_send_hour;
                 return datetime.format('HH');
             },
             set: function(value) {
                 this.mail_send_hour = value;
                 let datetime = this.$store.state.general.mail_send_datetime;
                 if (datetime != null) {
                     datetime.set('hour',this.mail_send_hour);
                     this.$store.dispatch('general/setDatetimeForSendingMail',datetime);
                 }
             },
         },
         formatted_mail_send_minute: {
             get: function() {
                 let datetime = this.$store.state.general.mail_send_datetime;
                 if (datetime == null) return this.mail_send_min;
                 return datetime.format('mm');
             },
             set: function(value) {
                 this.mail_send_min = value;
                 let datetime = this.$store.state.general.mail_send_datetime;
                 if (datetime != null) {
                     datetime.set('minute',this.mail_send_min);
                     this.$store.dispatch('general/setDatetimeForSendingMail',datetime);
                 }
             },
         },
     },
     data: function() {
         return {
             optionTimes: ["00","01","02","03","04","05","06","07",
                           "08","09","10","11","12","13","14","15",
                           "16","17","18","19","20","21","22","23"],
             optionMinutes: ['00','30'],
             mail_send_datetime: null,
             mail_send_hour: '09',
             mail_send_min: '00',
         }
     },
     mounted: function() {
         this.initializeDatetime();
     },
     methods: {
         initializeDatetime: function() {
             let datetime = this.$store.state.general.mail_send_datetime;
             if (datetime != null) return;
             datetime = this.getDefaultDatetime();
             this.$store.dispatch('general/setDatetimeForSendingMail',datetime);
         },
         getDefaultDatetime: function() {
             let m = moment();
             m = m.startOf('day');
             m = m.add(1,'days');
             m.set({hour: this.mail_send_hour, minute: this.mail_send_min});
             return m;
         },
         getSendMailFlag: function() {
             return this.$store.state.general.flg_mail;
         },
         getSendMailDatetime: function() {
             const dt = this.$store.state.general.mail_send_datetime;
             if (dt == null) return null;

             const fmtstr = dt.format('YYYY-MM-DD HH:mm')
             const check = moment(fmtstr);
             return check.isValid() ? dt : null;
         },
         getSendMailDatetimeFormattedString: function() {
             const dt = this.getSendMailDatetime();
             if (dt == null) return '';
             return dt.format();
         },
     }
 }
</script>
